import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { formatDate, formatDate2 } from '../../../common/utils';
import './index.css';

// const colors = ["#0b84a5", "#f6c85f", "#6f4e7c", "#9dd866", "#ca472f", "#ffa056", "#8dddd0",
//   "#ef3e5b", "#f68fa0", "#4b256d", "#6f5495", "#a09ed6", "#3f647e", "#688fad", "#9fc1d3",
//   "00bob2", "#52ccce", "#95d47a", "#677c8a", "#b2a296"]

export const SimpleLineChart = (props) => {

  const { yTooltipText, data } = props;

  if (!data) {
    return (
      <div className="container-simple-line-chart-mobile simple-line-chart">
        Загрузка...
      </div>
    )
  }

  const calcExtraValue = (value) => {

    if (value > 20) {
      return value * 0.2;
    } else {
      return 3;
    }

  };

  // rechart's chart does not correctly display some topics
  // have to calc min and max values manually
  const minValue = Math.min.apply(Math, data.map(item => item.yval));
  const maxValue = Math.max.apply(Math, data.map(item => item.yval));

  const startValue = Math.round(minValue - calcExtraValue(minValue));
  const endValue = Math.round(maxValue + calcExtraValue(maxValue));
  // const startValue = minValue - (minValue * 0.2);
  // const endValue = maxValue + (maxValue * 0.2);

  return (
    <ResponsiveContainer width="100%">
      <LineChart
        data={data}
        margin={{ top: 15, right: 10, bottom: 0, left: 10 }}>
        <Line type="monotone" dataKey="yval" name="индекс" stroke="#8884d8" dot={false} />
        <CartesianGrid stroke="#ccc" strokeDasharray="1 4" />
        <XAxis dataKey="xval"
          axisLine={false}
          tickLine={false}
          style={{
            fontSize: '0.7rem',
          }}
          tickFormatter={(val) => {
            return formatDate2(val);
          }}
        />
        <YAxis
          // tick={false}
          hide={true}
          axisLine={false}
          tickLine={false}
          tickCount={6}
          width={20}
          domain={[startValue, endValue]}
          // domain={['auto', 'auto']}
          // domain={['dataMin - 100', 'dataMax + 100']}
          // domain={[50, 500]}
          style={{
            fontSize: '0.6rem',
          }} />

        <Tooltip
          content={CustomTooltip(yTooltipText)}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

const CustomTooltip = (tooltipText) => ({ active, payload }) => {
  if (active) {
    // const dateValues = payload[0].payload.xval.split("-");
    // const date = new Date(dateValues[0], dateValues[1] - 1, dateValues[2]);
    return (<div className="simple-line-chart__tooltip">
      <div>{formatDate(payload[0].payload.xval)}</div>
      <div>{tooltipText}: {payload[0].payload.yval}</div>
    </div>);
  }

  return null;
}

export default SimpleLineChart;