import JavaPage from "./pages/top-languages/java";
import Navbar from "./components/html/navbar";
import React, { Suspense } from "react";
import {
  Switch,
  Route,
} from "react-router-dom";
import PythonPage from "./pages/top-languages/python";
import {
  CPLUSPLUS_TOPIC_ID, CSHARP_TOPIC_ID,
  DELPHI_TOPIC_ID,
  GO_TOPIC_ID, JAVASCRIPT_TOPIC_ID,
  JAVA_TOPIC_ID, KOTLIN_TOPIC_ID, PHP_TOPIC_ID,
  PYTHON_TOPIC_ID, RUBY_TOPIC_ID, SCALA_TOPIC_ID,
  SWIFT_TOPIC_ID, TYPESCRIPT_TOPIC_ID
} from "./common/topic-constants";
import TopLanguagesPage from "./pages/top-languages";
import JavascriptPage from "./pages/top-languages/javascript";
import LanguageNotReadyYetPage from "./pages/top-languages/language-not-ready-yet";
import CsharpPage from "./pages/top-languages/csharp";
import DirectionsPage from "./pages/demand/directions";
import Footer from "./components/html/footer";
import BelarusItStatusNavbarMobile from "./components/bel-it-jobs-status-navbar-mobile";
import { SeniorPositionsPage } from "./pages/positions/seniors";
import { JuniorPositionsPage } from "./pages/positions/juniors";
import { MiddlePositionsPage } from "./pages/positions/middles";
import PositionsPage from "./pages/positions";
import DevelopersPage from "./pages/demand/directions/developers";
import PhpPage from './pages/top-languages/php';
import CplusplusPage from './pages/top-languages/cplusplus';
import DemandVariousTechPage from './pages/demand/various-tech';
import AboutPage from './pages/about';
import { TopItCompaniesByEmployeesTablePage } from './pages/top-it-companies-by-employees';
import MainPage from './pages';
// import CoursesSearchPage from './pages/courses/courses-search';
import CoursePage from './pages/courses/course';
import LoadingPage from './pages/loading';
import LoginPage from './pages/login';
import RegisterPage from './pages/register';
import ProfilePage from './pages/profile';
import PrivateRoute from './common/private-route';
import { ADD_NEW_COURSE_PAGE, REGISTER_ACTIVATE_EMAIL_PAGE, LOGIN_PAGE, PROFILE_PAGE, REGISTER_PAGE, CONFIRM_EMAIL_PAGE, ADD_NEW_COURSE_SUCCESS_PAGE, EDIT_COURSE_PAGE } from './common/constants';
import RegisterConfirmEmailPage from './pages/register-confirm-email';
import ConfirmEmailPage from './pages/confim-email';
import AddCourseSuccessPage from './pages/courses/add-course-success';
import EditCoursePage from './pages/courses/edit-course';
import ScalaPage from './pages/top-languages/scala';
import AllBlogPostsPage from './pages/blog/all-blog-posts';
import BlogPostPage from './pages/blog/blog-post';
import PublicationsPage from './pages/publications';
import { LANGUAGES_TOP_PAGE, LIST_WITH_COURSES_AND_INTERNSHIPS_PAGE } from './common/relative-links';
import ResumesPage from './pages/resumes';
import TestAutomationEngineerPage from './pages/demand/directions/testing/automation';
import QaEngineerPage from './pages/demand/directions/testing/manual';
import TestingPage from './pages/demand/directions/testing';
import BackendPage from './pages/demand/directions/developers/backend';
import FrontendPage from './pages/demand/directions/developers/frontend';
import MobilePage from './pages/demand/directions/developers/mobile';
import GameDevPage from './pages/demand/directions/developers/gamedev';
import DevopsPage from './pages/demand/directions/devops';

const AddCoursePage = React.lazy(() => import('./pages/courses/add-course'));

function App() {

  return (
    <>
      <Navbar />
      <BelarusItStatusNavbarMobile />
      <Suspense fallback={<LoadingPage />}>
        <Switch>
          <Route exact path={LANGUAGES_TOP_PAGE}>
            <TopLanguagesPage />
          </Route>
          <Route exact path={`/languages/${JAVA_TOPIC_ID}`}>
            <JavaPage />
          </Route>
          <Route exact path={`/languages/${PYTHON_TOPIC_ID}`}>
            <PythonPage />
          </Route>
          <Route exact path={`/languages/${JAVASCRIPT_TOPIC_ID}`}>
            <JavascriptPage />
          </Route>
          <Route exact path={`/languages/${CSHARP_TOPIC_ID}`}>
            <CsharpPage />
          </Route>
          <Route exact path={`/languages/${PHP_TOPIC_ID}`}>
            <PhpPage />
          </Route>
          <Route exact path={`/languages/${CPLUSPLUS_TOPIC_ID}`}>
            <CplusplusPage />
          </Route>
          <Route exact path={`/languages/${SCALA_TOPIC_ID}`}>
            <ScalaPage />
          </Route>
          {[GO_TOPIC_ID,
            KOTLIN_TOPIC_ID,
            RUBY_TOPIC_ID,
            SCALA_TOPIC_ID,
            SWIFT_TOPIC_ID, TYPESCRIPT_TOPIC_ID, DELPHI_TOPIC_ID]
            .map(languageId => <Route key={languageId} exact path={`/languages/${languageId}`}>
              <LanguageNotReadyYetPage />
            </Route>)}


          <Route exact path="/path">
            <DirectionsPage />
          </Route>
          <Route exact path="/path/developers">
            <DevelopersPage />
          </Route>
          <Route exact path="/path/backend">
            <BackendPage />
          </Route>
          <Route exact path="/path/frontend">
            <FrontendPage />
          </Route>
          <Route exact path="/path/mobile">
            <MobilePage />
          </Route>
          <Route exact path="/path/devops">
            <DevopsPage />
          </Route>
          <Route exact path="/path/gamedev">
            <GameDevPage />
          </Route>

          <Route exact path="/path/testing">
            <TestingPage />
          </Route>
          <Route exact path="/path/testing/test-automation-engineer">
            <TestAutomationEngineerPage />
          </Route>
          <Route exact path="/path/testing/qa-engineer">
            <QaEngineerPage />
          </Route>

          <Route exact path="/positions">
            <PositionsPage />
          </Route>
          <Route exact path="/positions/seniors">
            <SeniorPositionsPage />
          </Route>
          <Route exact path="/positions/middles">
            <MiddlePositionsPage />
          </Route>
          <Route exact path="/positions/juniors">
            <JuniorPositionsPage />
          </Route>

          <Route exact path="/tops">
            <DemandVariousTechPage />
          </Route>
          <Route exact path="/demand-in-it/various-technologies">
            <DemandVariousTechPage />
          </Route>

          <Route exact path="/biggest-it-companies-by-employees-in-belarus">
            <TopItCompaniesByEmployeesTablePage />
          </Route>
          <Route exact path="/companies-top">
            <TopItCompaniesByEmployeesTablePage />
          </Route>

          <Route exact path="/resumes">
            <ResumesPage />
          </Route>

          <Route exact path="/course/:courseId">
            <CoursePage />
          </Route>

          <Route exact path="/it-courses-search">
            <PublicationsPage />
          </Route>

          <Route exact path={LIST_WITH_COURSES_AND_INTERNSHIPS_PAGE}>
            <PublicationsPage />
          </Route>

          <Route exact path="/about">
            <AboutPage />
          </Route>

          <Route exact path="/blog">
            <AllBlogPostsPage />
          </Route>

          <Route exact path="/blog/post/:blogPostId">
            <BlogPostPage />
          </Route>


          <Route exact path={LOGIN_PAGE}>
            <LoginPage />
          </Route>
          <Route exact path={REGISTER_PAGE}>
            <RegisterPage />
          </Route>
          <Route exact path={REGISTER_ACTIVATE_EMAIL_PAGE}>
            <RegisterConfirmEmailPage />
          </Route>
          <Route exact path={CONFIRM_EMAIL_PAGE}>
            <ConfirmEmailPage />
          </Route>

          <PrivateRoute exact path={ADD_NEW_COURSE_PAGE}>
            <AddCoursePage />
          </PrivateRoute>

          <PrivateRoute exact path={ADD_NEW_COURSE_SUCCESS_PAGE}>
            <AddCourseSuccessPage />
          </PrivateRoute>

          <PrivateRoute exact path={EDIT_COURSE_PAGE}>
            <EditCoursePage />
          </PrivateRoute>

          <PrivateRoute exact path={PROFILE_PAGE}>
            <ProfilePage />
          </PrivateRoute>

          <Route path="/">
            <MainPage />
          </Route>
        </Switch>
      </Suspense>
      <Footer />
    </>
  );
}
export default App;
