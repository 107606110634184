import React, { useContext, useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  Space,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  // COUNTRIES_ENDPOINT,
  COURSE_BY_ID_ENDPOINT,
  COURSE_TAGS_ENDPOINT
} from '../../../common/constants';
import HtmlHeader from '../../html/html-header';
import { useUpdateCourse } from './updateCourseHook';
import AuthenticationContext from '../../../common/auth-context/intex';
import { getRequest } from '../../../common/client';
import {
  COURSE_CONTACT_TYPES,
  // COURSE_COUNTRIES_RULES,
  // COURSE_COUNTRY_TOOLTIP_MESSAGE,
  COURSE_DESC_FULL_RULES,
  COURSE_DESC_RULES,
  COURSE_NAME_RULES,
  COURSE_TAG_RULES,
  COURSE_TYPE_RULES
} from '../utils';
import './index.css';

const { Option } = Select;

// TODO Reminder! When updating this component also update AddCourse component 
export const EditCourse = ({ courseId }) => {

  const { hasCourseProviderFeature, hasMentorFeature } = useContext(AuthenticationContext);

  const [form] = Form.useForm();
  const [tags, setTags] = useState([]);
  // const [countries, setCountries] = useState([]);
  const [updateCourse, errors] = useUpdateCourse();
  const [course, setCourse] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const { data, ok } = await getRequest(COURSE_TAGS_ENDPOINT, true);

        if (ok) {
          setTags(data);
        }

      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const { data, ok } = await getRequest(COUNTRIES_ENDPOINT, true);

  //       if (ok) {
  //         setCountries(data);
  //       }

  //     } catch (error) {
  //       console.log(error);
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    (async () => {
      try {
        const { data, ok } = await getRequest(COURSE_BY_ID_ENDPOINT.replace("{courseId}", courseId), true);
        setCourse(data);

        const dataForInitForm = { ...data };
        const { contacts } = dataForInitForm;
        contacts.sort((contact1, contact2) => contact1.id - contact2.id);
        dataForInitForm.contacts = contacts.map(contact => {
          return { contactType: contact.contactType, contactValue: contact.value, id: contact.id }
        });

        dataForInitForm.tag = dataForInitForm.tag.id;

        if (ok) {
          form.setFieldsValue(dataForInitForm);
        }

      } catch (error) {
        console.log(error);
      }
    })();
  }, [courseId]);

  const handleClick = async (values) => {

    const body = {
      ...values,
      type: course.type,
      countries: []
    }

    updateCourse({ courseId, body });
  };

  const courseTypes = [];

  const courseProviderFeature = hasCourseProviderFeature();
  const mentorFeature = hasMentorFeature();

  if (courseProviderFeature) {
    courseTypes.push({ name: "Курс", value: "COURSE" });
  } else if (mentorFeature) {
    courseTypes.push({ name: "Менторинг", value: "MENTORING" });
  }

  const tryShowError = (fieldName) => {
    return (errors ?
      (<div className="add-course__error">
        {errors ? errors[fieldName] : null}
      </div>)
      : null
    )
  };

  return (
    <div>
      <div className="add-course__title">
        <HtmlHeader text="Редактирование курса" type="h2" withMargin={true} />
      </div>
      <div className="add-course container">

        <div className="add-course__form">
          {errors ? <div className="add-course__error">
            <p>
              Ошибка во время сохранения изменений
            </p>
            {/* <p>{errors}</p> */}
          </div> : null}
          <Form
            form={form}
            layout="vertical"
            onFinish={handleClick}
          >
            <Form.Item
              label="Тип: "
              name="courseType"
              rules={COURSE_TYPE_RULES}
            >
              <Select disabled>
                {courseTypes.map(type => (
                  <Select.Option key={type.value} value={type.value}>{type.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Название:"
              name="name"
              rules={COURSE_NAME_RULES}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item noStyle>
              <Form.Item
                label="Краткое описание:"
                name="descriptionShort"
                rules={COURSE_DESC_RULES}

              >
                <Input.TextArea />
              </Form.Item>
              {tryShowError("descriptionShort")}
            </Form.Item>

            <Form.Item noStyle>
              <Form.Item
                label="Полное описание:"
                name="descriptionFull"
                rules={COURSE_DESC_FULL_RULES}
              >
                <Input.TextArea rows={10} showCount maxLength={5000} />
              </Form.Item>
              {tryShowError("descriptionFull")}
            </Form.Item>

            <Form.Item
              label="Направление:"
              name="tag"
              rules={COURSE_TAG_RULES}
            >
              <Select>
                {tags.map(tag => <Option key={tag.id} value={tag.id}>{tag.displayText}</Option>)}
              </Select>
            </Form.Item>

            <Form.Item noStyle>
              <Form.Item
                label="Стоимость, рублей (введите 0 если оплата не требуется):"
                name="price"
              >
                <InputNumber min={0} />
              </Form.Item>
              {tryShowError("price")}
            </Form.Item>

            <Form.Item label="Контакты:" required>
              <Form.List name="contacts">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(field => (
                      <Space key={field.key} className="add-course__contact" >
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, curValues) =>
                            prevValues.contacts !== curValues.contacts
                          }
                        >
                          {() => (
                            <Form.Item
                              {...field}
                              name={[field.name, 'contactType']}
                              fieldKey={[field.fieldKey, 'contactType']}
                              rules={[{ required: true, message: 'выберите тип' }]}
                            >
                              <Select style={{ width: 150 }} placeholder="тип">
                                {COURSE_CONTACT_TYPES.map((item) => (
                                  <Select.Option key={item.type} value={item.type}>
                                    {item.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          )}
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'contactValue']}
                          fieldKey={[field.fieldKey, 'contactValue']}
                          rules={[{ required: true, message: 'введите значение' }]}
                        >
                          <Input style={{ width: 300 }} placeholder="значение" />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'id']}
                          fieldKey={[field.fieldKey, 'id']}
                          hidden
                        >
                          <Input />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                      </Space>
                    ))}

                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Добавить контакт
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              {tryShowError("contacts")}
            </Form.Item>

            {/* <Form.Item
              label="Страна(ы):"
              name="countries"
              tooltip={COURSE_COUNTRY_TOOLTIP_MESSAGE}
              rules={COURSE_COUNTRIES_RULES}
            >
              <Select mode="multiple">
                {countries.map(country => <Option key={country.id} value={country.id}>{country.name}</Option>)}
              </Select>
            </Form.Item> */}

            <Form.Item className='add-course__add-button-container'>
              <Button className='add-course__add-button' type="primary" htmlType="submit">
                Сохранить изменения
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div >
  );
}