import {
  DJANGO_TOPIC_ID,
  FLASK_TOPIC_ID,
  JUNIOR_PYTHON_DEVELOPER_TOPIC_ID,
  PYTHON_DEVELOPER_TOPIC_ID,
  PYTHON_TOPIC_ID,
  SENIOR_PYTHON_DEVELOPER_TOPIC_ID
} from "../../../common/topic-constants";
import HtmlHeader from "../../../components/html/html-header";
import TopicChartContainer from "../../../components/charts/topic-chart-container";
import { AbstractPage } from '../../abstractPage';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const PythonPage = () => {
  return (<AbstractPage>
    <HtmlHeader text="Страница языка Python" type="h1" withMargin={true} />
    <TopicChartContainer topicId={PYTHON_TOPIC_ID} />
    <Tabs>
      <TabList>
        <Tab>Позиции</Tab>
        <Tab>Библиотеки</Tab>
      </TabList>
      <TabPanel>
        <HtmlHeader text="Позиции для Python разработчиков:" type="h2" withMargin={true} />
        <TopicChartContainer topicId={PYTHON_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={JUNIOR_PYTHON_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={SENIOR_PYTHON_DEVELOPER_TOPIC_ID} />
      </TabPanel>
      <TabPanel>
        <HtmlHeader text="Библиотеки и фреймворки для Python:" type="h2" withMargin={true} />
        <TopicChartContainer topicId={FLASK_TOPIC_ID} />
        <TopicChartContainer topicId={DJANGO_TOPIC_ID} />
      </TabPanel>
    </Tabs>


  </AbstractPage>);
}

export default PythonPage;