import CardLink from "../../components/html/card-link";
import HtmlHeader from "../../components/html/html-header";
import { AbstractPage } from '../abstractPage';

const PositionsPage = () => {
  return (<AbstractPage>
    <HtmlHeader text="Востребованность Junior, Middle и Senior специалистов" type="h1" withMargin={true} />
    <CardLink
      title="Senior позиции"
      text=""
      url="/positions/seniors" />


    <CardLink
      title="Middle позиции"
      text=""
      url="/positions/middles" />

    <CardLink
      title="Junior позиции"
      text=""
      url="/positions/juniors" />

    {
    // слишком мало данных!
    // надо подождать 1 месяц

    /* <CardLink
      title="Востребованность Junior разработчиков"
      text=""
      url="/positions/junior-developers" /> */}

  </AbstractPage>);
}

export default PositionsPage;