import {
  SENIOR_JAVA_DEVELOPER_TOPIC_ID,
  SENIOR_PYTHON_DEVELOPER_TOPIC_ID,
  SENIOR_GO_DEVELOPER_TOPIC_ID,
  SENIOR_PHP_DEVELOPER_TOPIC_ID,
  SENIOR_RUBY_DEVELOPER_TOPIC_ID,
  SENIOR_NODE_DEVELOPER_TOPIC_ID,
  SENIOR_ANGULAR_DEVELOPER_TOPIC_ID,
  SENIOR_ANDROID_DEVELOPER_TOPIC_ID,
  SENIOR_IOS_DEVELOPER_TOPIC_ID,
  SENIOR_NET_DEVELOPER_TOPIC_ID,
  SENIOR_REACT_DEVELOPER_TOPIC_ID,
  SENIOR_CPULSPLUS_DEVELOPER_TOPIC_ID,
  SENIOR_SCALA_DEVELOPER_TOPIC_ID,
  SENIOR_VUE_DEVELOPER_TOPIC_ID,
  SENIOR_QA_ENGINEER_TOPIC_ID,
  SENIOR_QA_AUTOMATION_ENGINEER_TOPIC_ID,
} from "../../../common/topic-constants";
import HtmlHeader from "../../../components/html/html-header";
import TopicChartContainer from "../../../components/charts/topic-chart-container";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { AbstractPage } from '../../abstractPage';

export const SeniorPositionsPage = () => {
  return (<AbstractPage>
    <HtmlHeader text="Востребованность Senior специалистов" type="h1" withMargin={true} />

    <Tabs>
      <TabList>
        <Tab>Backend</Tab>
        <Tab>Frontend</Tab>
        <Tab>Mobile</Tab>
        <Tab>GameDev</Tab>
        <Tab>Testing</Tab>
      </TabList>
      <TabPanel>
        <TopicChartContainer topicId={SENIOR_JAVA_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={SENIOR_PYTHON_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={SENIOR_NET_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={SENIOR_PHP_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={SENIOR_GO_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={SENIOR_RUBY_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={SENIOR_NODE_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={SENIOR_CPULSPLUS_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={SENIOR_SCALA_DEVELOPER_TOPIC_ID} />
      </TabPanel>
      <TabPanel>
        <TopicChartContainer topicId={SENIOR_ANGULAR_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={SENIOR_REACT_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={SENIOR_VUE_DEVELOPER_TOPIC_ID} />
      </TabPanel>
      <TabPanel>
        <TopicChartContainer topicId={SENIOR_ANDROID_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={SENIOR_IOS_DEVELOPER_TOPIC_ID} />
      </TabPanel>
      <TabPanel>
        В ближайшее время тут появится график
      </TabPanel>
      <TabPanel>
        <TopicChartContainer topicId={SENIOR_QA_ENGINEER_TOPIC_ID} />
        <TopicChartContainer topicId={SENIOR_QA_AUTOMATION_ENGINEER_TOPIC_ID} />
      </TabPanel>
    </Tabs>

  </AbstractPage >);
}
