import introImage from '../../../assets/intro.png';
import './index.css';

export const MainPageIntro = () => {

  return (<div className="main-page-intro">
    <div className="main-page-intro__left">
      <div>
        {/* <h1>Знай состояние рынка IT вакансий в Беларуси</h1> */}
        {/* <p>Благодаря регулярно обновляемым данным по языкам программирования, позициям (Junior, Middle, Senior), фреймворкам, библиотекам</p> */}
        <h1>Аналитика рынка IT вакансий в Беларуси</h1>
        <p>Данные по востребованности языков программирования (фреймворков и библиотек); разработчиков, тестировщиков и других позиций разного уровня (Junior, Middle, Senior)</p>
      </div>
    </div>
    <div className="main-page-intro__right">
      <img src={introImage} className="main-page-intro__right-image" alt="intro" />
    </div>
  </div>);

}