import React from 'react';
import { LANGUAGES_TOP_PAGE } from '../../../common/relative-links';
import ChangeInPercent from '../../change-in-percent';
import { ConfigurableTable } from '../configurable-table';

export const JobsTable = (props) => {

  const { rowLinkBase, rowLinkParamName, showPlace, showWeekChange,
    showMonthChange, capitalizedNames = false } = props;

  const columns = React.useMemo(
    () => {
      let cols = [
        {
          Header: 'Название',
          accessor: 'name',
        },
        {
          Header: 'Количество вакансий',
          accessor: 'index',
        }
      ];

      if (showPlace) {
        cols.unshift({
          Header: '#',
          accessor: 'place',
        })
      }

      if (showWeekChange) {
        cols.push({
          Header: '7 дней',
          accessor: 'week',
        });
      }

      if (showMonthChange) {
        cols.push({
          Header: '1 месяц',
          accessor: 'month',
        });
      }

      return cols;
    },
    [showPlace, showWeekChange, showMonthChange]
  )

  const dataMapper = ({ item, place }) => {
    const { topicName, lastValue, change, monthChange } = item;

    let result = {
      link: rowLinkBase + item[rowLinkParamName],
      name: capitalizedNames ? <span className="capitalize-text">{topicName}</span> : topicName,
      index: lastValue
    }

    if (showPlace) {
      result["place"] = place;
    }

    if (showMonthChange) {
      result["week"] = <ChangeInPercent change={change} makeTextBold={false} />;
    }

    if (showPlace) {
      result["month"] = <ChangeInPercent change={monthChange} makeTextBold={false} />;
    }

    return result;
  };

  return (<ConfigurableTable
    {...props}
    fullTablePageUrl={LANGUAGES_TOP_PAGE}
    removeFirstColumnInMobileVersion
    columns={columns}
    dataMapper={dataMapper}
  />);
}
