import HtmlHeader from "../../components/html/html-header";
import { AbstractPage } from '../abstractPage';

const AboutPage = () => {
  return (<AbstractPage >
    <HtmlHeader text="О сайте" type="h1" withMargin={true} />
    <div className="container">
      <p>
        Сайт devpulse ставит своей целью предоставить возможность объективно оценить состояние рынка IT вакансий в Беларуси. Узнать востребованность языков программирования, позиций разного уровня (Junior, Middle, Senior), а так же востребованность фреймворков и библиотек.
      </p>
      <h4>Откуда берутся данные?</h4>
      <p>
        Данные собираются с сайтов с вакансиями из РБ.
      </p>
    </div>
  </AbstractPage>);
}

export default AboutPage;