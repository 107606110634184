import {
  DRUPAL_TOPIC_ID,
  JUNIOR_PHP_DEVELOPER_TOPIC_ID,
  LARAVEL_TOPIC_ID,
  LINUX_TOPIC_ID,
  MAGENTO_TOPIC_ID,
  MIDDLE_PHP_DEVELOPER_TOPIC_ID,
  MYSQL_TOPIC_ID,
  PHP_DEVELOPER_TOPIC_ID,
  PHP_TOPIC_ID,
  SENIOR_PHP_DEVELOPER_TOPIC_ID,
  SYMFONY_TOPIC_ID,
  WORDPRESS_DEVELOPER_TOPIC_ID,
  WORDPRESS_TOPIC_ID
} from "../../../common/topic-constants";
import HtmlHeader from "../../../components/html/html-header";
import TopicChartContainer from "../../../components/charts/topic-chart-container";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { AbstractPage } from '../../abstractPage';

const PhpPage = () => {
  return (<AbstractPage>
    <HtmlHeader text="Страница языка PHP" type="h1" withMargin={true} />
    <TopicChartContainer topicId={PHP_TOPIC_ID} />
    <Tabs>
      <TabList>
        <Tab>Позиции</Tab>
        <Tab>Фреймворки</Tab>
        <Tab>CMS</Tab>
        <Tab>Другое</Tab>
      </TabList>
      <TabPanel>
        <HtmlHeader text="Позиции для PHP разработчиков:" type="h2" withMargin={true} />
        <TopicChartContainer topicId={PHP_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={JUNIOR_PHP_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={MIDDLE_PHP_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={SENIOR_PHP_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={WORDPRESS_DEVELOPER_TOPIC_ID} />
      </TabPanel>
      <TabPanel>
        <HtmlHeader text="PHP фреймворки:" type="h2" withMargin={true} />
        <TopicChartContainer topicId={LARAVEL_TOPIC_ID} />
        <TopicChartContainer topicId={SYMFONY_TOPIC_ID} />
      </TabPanel>
      <TabPanel>
        <HtmlHeader text="CMS написанные на PHP:" type="h2" withMargin={true} />
        <TopicChartContainer topicId={WORDPRESS_TOPIC_ID} />
        <TopicChartContainer topicId={MAGENTO_TOPIC_ID} />
        <TopicChartContainer topicId={DRUPAL_TOPIC_ID} />
      </TabPanel>
      <TabPanel>
        <HtmlHeader text="Связанное с PHP:" type="h2" withMargin={true} />
        <TopicChartContainer topicId={MYSQL_TOPIC_ID} />
        <TopicChartContainer topicId={LINUX_TOPIC_ID} />
      </TabPanel>
    </Tabs>
  </AbstractPage>);
}

export default PhpPage;