import React from 'react';
import { Alert } from 'antd';

export const DevpulseAlert = (props) => {

  const { type, message } = props;

  return (
    <Alert type={type} message={message}></Alert>
  );
}

export default DevpulseAlert;