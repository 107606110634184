import {
  UNITY_TOPIC_ID,
  UNITY_DEVELOPER_TOPIC_ID,
  UNREAL_ENGINE_TOPIC_ID,
  JUNIOR_UNITY_DEVELOPER_TOPIC_ID,
  MIDDLE_UNITY_DEVELOPER_TOPIC_ID,
} from "../../../../../common/topic-constants";
import HtmlHeader from "../../../../../components/html/html-header";
import TopicChartContainer from "../../../../../components/charts/topic-chart-container";
import { AbstractPage } from '../../../../abstractPage';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { developersFrontendRelatedList } from '../../../../related-lists';
import RelatedPages from '../../../../../components/related-pages';
import { useEffect } from 'react';

const GameDevPage = () => {

  useEffect(() => {
    document.title = "GameDev направление. Востребованность технологий и позиций | Devpulse.by — аналитика рынка IT вакансий в Беларуси";
  }, []);

  return (<AbstractPage>
    <HtmlHeader text="GameDev направление. Востребованность технологий и позиций" type="h1" withMargin={true} />
    <Tabs>
      <TabList>
        <Tab>Технологии</Tab>
        <Tab>Позиции</Tab>
      </TabList>
      <TabPanel>
        <HtmlHeader text="GameDev технологии:" type="h2" withMargin={true} />
        <TopicChartContainer topicId={UNITY_TOPIC_ID} />
        <TopicChartContainer topicId={UNREAL_ENGINE_TOPIC_ID} />
      </TabPanel>
      <TabPanel>
        <HtmlHeader text="Позиции разработчиков" type="h2" withMargin={true} />
        <TopicChartContainer topicId={UNITY_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={JUNIOR_UNITY_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={MIDDLE_UNITY_DEVELOPER_TOPIC_ID} />
        {/* <TopicChartContainer topicId={SENIOR_UNITY_DEVELOPER_TOPIC_ID} /> */}
      </TabPanel>
    </Tabs>
    <RelatedPages list={developersFrontendRelatedList} />
  </AbstractPage>);
}

export default GameDevPage;