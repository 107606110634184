import {
  ANDROID_DEVELOPER_TOPIC_ID,
  IOS_DEVELOPER_TOPIC_ID,
  JAVASCRIPT_TOPIC_ID,
  JAVA_TOPIC_ID,
  KOTLIN_TOPIC_ID,
  OBJECTIVEC_TOPIC_ID,
  REACT_NATIVE_DEVELOPER_TOPIC_ID,
  SWIFT_TOPIC_ID,
  FLUTTER_TOPIC_ID,
  FLUTTER_DEVELOPER_TOPIC_ID
} from "../../../../../common/topic-constants";
import DirectionDefinition from "../../../../../components/html/directions/direction-definition";
import HtmlHeader from "../../../../../components/html/html-header";
import TopicChartContainer from "../../../../../components/charts/topic-chart-container";
import { AbstractPage } from '../../../../abstractPage';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { developersFrontendRelatedList } from '../../../../related-lists';
import RelatedPages from '../../../../../components/related-pages';

const MobilePage = () => {
  return (<AbstractPage>
    <HtmlHeader text="Mobile направление" type="h1" withMargin={true} />
    <DirectionDefinition
      title="Что такое Mobile?"
      text="Mobile - мобильные приложения для телефонов и планшетов на Android и IOS" />

    <Tabs>
      <TabList>
        <Tab>Языки</Tab>
        <Tab>Позиции</Tab>
      </TabList>
      <TabPanel>
        <HtmlHeader text="Популярные Mobile языки:" type="h2" withMargin={true} />
        <TopicChartContainer topicId={KOTLIN_TOPIC_ID} />
        <TopicChartContainer topicId={SWIFT_TOPIC_ID} />
        <TopicChartContainer topicId={JAVA_TOPIC_ID} />
        <TopicChartContainer topicId={OBJECTIVEC_TOPIC_ID} />
        <TopicChartContainer topicId={JAVASCRIPT_TOPIC_ID} />
        <TopicChartContainer topicId={FLUTTER_TOPIC_ID} />
      </TabPanel>
      <TabPanel>
        <HtmlHeader text="Позиции разработчиков мобильных приложений" type="h2" withMargin={true} />
        <TopicChartContainer topicId={ANDROID_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={IOS_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={REACT_NATIVE_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={FLUTTER_DEVELOPER_TOPIC_ID} />
      </TabPanel>
    </Tabs>
    <RelatedPages list={developersFrontendRelatedList} />
  </AbstractPage>);
}

export default MobilePage;