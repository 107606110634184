import { Link } from 'react-router-dom';
import "./index.css";

const Footer = () => {
  return (<div className="footer">
    <div className="footer__container">
      {/* <div className="footer__question">
        <h4>Для чего этот сайт?</h4>
        <div>Этот сайт позволяет понять как именно меняется рынок IT в Беларуси через популярность языков программирования, фреймворков, библиотек, позиций (Junior, Middle, Senior) и других, связанных с IT тем</div>
      </div>
      <div className="footer__question">
        <h4>Откуда берутся данные?</h4>
        <div>Мы регулярно мониторим площадки с вакансиями и на их основе составляем индекс популярности</div>
      </div> */}
      <div className="footer__links">
        <Link className="footer__link" to="/about" rel="noreferrer">О сайте</Link>
        <Link className="footer__link" to="/blog" rel="noreferrer">Блог</Link>
        <a className="footer__link" target="_blank" href="https://www.instagram.com/devpulse.by/" rel="noreferrer">Наш инстаграм</a>
      </div>
    </div>
  </div >);
}

export default Footer;