
import './index.css';

/**
 * This component allows to locate two components at the same level
 * for desktop mode so each component has 50% of total width of the page.
 */
const TwoInOneWrapper = ({ componentOne, componentTwo }) => {

  return (
    <div className="two-in-one-wrapper">
      <div className="two-in-one-wrapper__one">
        {componentOne}
      </div>
      <div className="two-in-one-wrapper__two">
        {componentTwo}
      </div>
    </div>);
}

export default TwoInOneWrapper;