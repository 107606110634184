import React, { useContext, useState } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { Form, Input, Button, Alert } from 'antd';
import HtmlHeader from '../../html/html-header';
import { postRequest } from '../../../common/client';
import { LOGIN_ENDPOINT, PROFILE_PAGE, REGISTER_PAGE } from '../../../common/constants';
import AuthenticationContext from '../../../common/auth-context/intex';
import './index.css';

const Login = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const { setToken } = useContext(AuthenticationContext);

  const { state } = useLocation();

  const doLogin = async (values) => {

    try {
      const { ok, data } = await postRequest(LOGIN_ENDPOINT, values, false);
      if (ok) {
        const { token } = data;
        setErrorMessage(null);
        setToken(token);
        setRedirectToReferrer(true);
        // history.push("/profile")
      } else {
        setErrorMessage("Не удалось войти в аккаунт");
      }
    } catch (error) {
      console.log(error);
      setErrorMessage("Не удалось войти в аккаунт");
    }
  };

  const clearErrorMessage = () => {
    setErrorMessage(null);
  }

  if (redirectToReferrer === true) {
    return <Redirect to={state?.from || PROFILE_PAGE} />
  }

  return (
    <div className='devpulse__login-outer-сontainer'>
      <HtmlHeader text="Войти в аккаунт" type='h2' />
      <div className="devpulse__login__register-text"> или <Link to={REGISTER_PAGE}>создать новый аккаунт</Link></div>
      <br></br>
      <div className='devpulse__login-inner-сontainer container'>
        {errorMessage ? <Alert message={errorMessage} type="error" closable onClose={clearErrorMessage} /> : null}
        <Form
          name="login"
          layout='vertical'
          labelCol={{
            span: 16,
          }}
          wrapperCol={{
            span: 32,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={doLogin}
        >
          <Form.Item
            label="Электронная почта"
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Пожалуйста, введите электронную почту',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Пароль"
            name="password"
            rules={[
              {
                required: true,
                message: 'Пожалуйста, введите пароль',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="devpulse__login__button">
              Войти
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default Login;