import React, { useState, useEffect } from 'react';
import { RECORDS_ENDPOINT } from '../../../common/constants';
import SimpleLineChart from '../simple-line-chart';
import HtmlHeader from '../../html/html-header';
// import DevpulseTooltip from '../html/tooltip';

export const TopicChart = (props) => {
  const [data, setData] = useState(0);
  const [topicName, setTopicName] = useState(0);
  /* eslint-disable no-unused-vars */
  const [range, setRange] = useState(-1);

  const { topicId, type, period } = props;

  const buildUrl = (topicId, range, type, period) => {

    const urlBuilder = [];
    urlBuilder.push(RECORDS_ENDPOINT);
    urlBuilder.push(`?topicId=${topicId}`);
    urlBuilder.push(`&range=${range}`);

    if (type) {
      urlBuilder.push(`&type=${type}`);
    }

    if (period) {
      urlBuilder.push(`&period=${period}`);
    }

    return urlBuilder.join("");
  }

  useEffect(() => {
    const url = buildUrl(topicId, range, type, period);
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => response.json())
      .then(data => {
        const { records, topicName } = data;
        const list = [];
        records.forEach(element => {
          list.push({
            xval: element.date, yval: element.value
          })
        });
        setData(list);
        setTopicName(topicName);
      });

  }, [topicId, range]);


  if (!data) {
    return (
      <div className="container-simple-line-chart-mobile simple-line-chart">
        Загрузка...
      </div>
    )
  }

  const currentValue = data ? data[data.length - 1].yval : '';
  const previousValue = data ? data[data.length - 2].yval : '';

  const renderDifference = (currentValue, previousValue) => {
    const tempValue = data ? currentValue / previousValue * 100 : '';

    if (previousValue == 0 && currentValue > 0) {
      return (
        <span className="simple-line-chart__change positive">
          рост на 100%
        </span>);
    } else if (previousValue === currentValue) {
      return (
        <span className="simple-line-chart__change positive">
          0%
        </span>);
    } else if (tempValue >= 100) {
      const difference = (tempValue - 100).toFixed(1);
      return (
        <span className="simple-line-chart__change positive">
          рост на {difference}%
        </span>);
    } else {
      const difference = (100 - tempValue).toFixed(1);
      return (
        <span className="simple-line-chart__change negative">
          снижение на {difference}%
        </span>);
    }
  };

  return (
    <div>
      <div className="simple-line-chart__header">
        <div className="simple-line-chart__title capitalize-text">
          <HtmlHeader text={topicName} type="h3" />
        </div>
        <div className="simple-line-chart__current-value-container">
          <div className="simple-line-chart__current-value">
            Количество {type === 'resumes' ? "резюме" : "вакансий"}: {currentValue}
          </div>
          <div className="simple-line-chart__current-value-info">
            {/* <DevpulseTooltip id={topicId} text="Количество вакансий рассчитывается на основе данных с различных площадок с вакансиями" /> */}
          </div>
        </div>
        <div className="simple-line-chart__change-container">
          За неделю: {renderDifference(currentValue, previousValue)}
        </div>
        {/* <div className="simple-line-chart__change-container">
          За месяц: {renderDifference(currentValue, previousValue)}
        </div> */}
      </div>
      <div className="simple-line-chart">
        <SimpleLineChart data={data} yTooltipText={type === 'resumes' ? "резюме" : "вакансий"} />
      </div>
      {/* <div className='pick-period-container'>
        <div className={"pick-period-item" + (range === 1 ? " pick-period-item-selected" : "")} onClick={() => setRange(3)}>
          3 месяца
        </div>
        <div className={"pick-period-item" + (range === 3 ? " pick-period-item-selected" : "")} onClick={() => setRange(-1)}>
          Все время
        </div>
      </div> */}
    </div>
  );
}

export default TopicChart;