import { useEffect, useState } from 'react';
import { getRequest } from '../../../common/client';
import { BLOG_POST_BY_ID_ENDPOINT } from '../../../common/constants';
import { formatDate } from '../../../common/utils';
import HtmlHeader from '../../html/html-header';
import './index.css';

const BlogPost = ({ blogPostId }) => {
  const [blogPost, setBlogPost] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    (async () => {
      try {
        const { ok, data } = await getRequest(BLOG_POST_BY_ID_ENDPOINT.replace("{blogPostId}", blogPostId), false);

        if (ok) {
          setBlogPost(data);
        } else {
          setError("Не удалось открыть публикацию");
        }
      } catch (error) {
        setError("Не удалось открыть публикацию");
      }
    })();
  }, [blogPostId])

  if (error) {
    return (<div className='container'>{error}</div>);
  }

  if (!blogPost) {
    return <div className='container'>Загрузка...</div>
  }

  const { title, content, created } = blogPost;

  return (<div className="container">
    <div className='blog-post__created'>Опубликовано {formatDate(created)}</div>
    <HtmlHeader text={title} type="h1" />
    <div>
      <div className="blog-post__description"
        dangerouslySetInnerHTML={{ __html: content.replaceAll('\n', "<p><p/>") }} />
    </div>
  </div>);
}

export default BlogPost;