import AllBlogPosts from '../../../components/blog/all-blog-posts';
import HtmlHeader from '../../../components/html/html-header';
import RelatedPages from '../../../components/related-pages';
import { AbstractPage } from '../../abstractPage';
import { courseRelatedPagesList } from '../../related-lists';

const AllBlogPostsPage = () => {

  return (<AbstractPage>
    <HtmlHeader text="Блог" type="h1" withMargin={true} />
    <AllBlogPosts />
    <RelatedPages list={courseRelatedPagesList} />
  </AbstractPage>);
}

export default AllBlogPostsPage;