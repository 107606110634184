import { useEffect } from 'react';
import HtmlHeader from '../../components/html/html-header';
import Keywords from '../../components/html/keywords';
import Publications from '../../components/publications';
import RelatedPages from '../../components/related-pages';
import { AbstractPage } from '../abstractPage';
import { courseRelatedPagesList, devstartxyzPagesList } from '../related-lists';
import { Alert } from 'antd';

const PublicationsPage = () => {

  useEffect(() => {
    document.title = "Список всех курсов и стажировок от IT компаний в РБ | Devpulse.by";
  }, []);

  return (<AbstractPage >
    <HtmlHeader text="Список всех курсов и стажировок от IT компаний в РБ" type="h1" withMargin={true} />
    <Alert type='warning' message={
      <div>
        <span>Данная страница больше не обновляется. Самую актуальную информацию вы найдете тут — </span>
        <a href='https://devstart.xyz/ru/belarus/vse-ayti-kursy-i-stajirovki'>https://devstart.xyz/ru/belarus/vse-ayti-kursy-i-stajirovki</a>
      </div> 
    }></Alert>
    <br></br>
    <Publications />
    <RelatedPages list={devstartxyzPagesList} numberOfRelated={4} absoluteLinks={true} />
    <RelatedPages list={courseRelatedPagesList} numberOfRelated={5} />
    <Keywords values={["epam стажировка", "стажировка frontend", "iba стажировка", "itransition стажировка", "стажировка javascript",
      "стажировка js", "стажировка qa", "стажировка java", "стажировка it беларусь", "стажировка devops", "intexsoft стажировка",
      "a1qa стажировка", "стажировка python", "java стажировка", "expert soft стажировка", "стажировки it", "syberry стажировка",
      "qa automation python курсы"]} />
  </AbstractPage>);
}

export default PublicationsPage;