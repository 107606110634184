import { Link } from "react-router-dom";
import "./index.css";

/* Randomize array in-place using Durstenfeld shuffle algorithm */
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    let temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

const RelatedPages = ({ list, numberOfRelated = 3, absoluteLinks=false }) => {

  shuffleArray(list);

  const topFiveItems = list.slice(0, numberOfRelated);

  return (<div className='container'>
    <div className='related-pages__title'>Может быть интересно:</div>
    <div className='related-pages__container'>
      {topFiveItems.map(item => <div key={item.link} className='related-pages__item'>
        {absoluteLinks 
          ? <a href={item.link}>{item.title}</a>
          : <Link to={item.link} >{item.title}</Link>  
        }
      </div>)}
    </div>
  </div>);
}

export default RelatedPages;