import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { getRequest } from '../../../common/client';
import { CONFIRM_EMAIL_ENDPOINT } from '../../../common/constants';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}


const ConfirmEmail = () => {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(true);

  let query = useQuery();
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data, ok } = await getRequest(`${CONFIRM_EMAIL_ENDPOINT}?token=${query.get("token")}`, false);

        if (ok) {
          setMessage("Адрес электронной почты подтвержден! Теперь вы можете войти");
        } else {
          const { message } = data;
          setMessage(message);
        }

      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [query]);

  return (
    <div className='container'>
      <h3>
        Подтверждение адреса электронной почты
      </h3>
      <p>
        {loading ? "Подождите..." : message}
      </p>
      <br />
      <div>
        <NavLink to="/login">Войти в кабинет</NavLink>
      </div>
    </div>
  );
}

export default ConfirmEmail; 