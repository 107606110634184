import React from 'react';
import ConfirmEmail from '../../components/user/confirm-email';
import { AbstractPage } from '../abstractPage';

const ConfirmEmailPage = () => {

  return (
    <AbstractPage>
      <ConfirmEmail />
    </AbstractPage>
  );
}

export default ConfirmEmailPage;