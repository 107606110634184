import { useParams } from 'react-router-dom';
import BlogPost from '../../../components/blog/blog-post';
import RelatedPages from '../../../components/related-pages';
import { AbstractPage } from '../../abstractPage';
import { courseRelatedPagesList } from '../../related-lists';

const BlogPostPage = () => {

  const { blogPostId } = useParams();

  return (<AbstractPage>
    <BlogPost blogPostId={blogPostId} />
    <RelatedPages list={courseRelatedPagesList} />
  </AbstractPage>);
}

export default BlogPostPage;