import "./index.css";

const Keywords = ({ values }) => {

  return (
    <div className="container">
      <div className="keywords-container">
        Ключевые слова: {values.map(value => <span className='keywords-container__keyword'>{value}</span>)}
      </div>
    </div>
  );
}

export default Keywords;