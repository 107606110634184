import React, { useState, useEffect } from 'react';
import { RECORDS_ENDPOINT } from '../../../common/constants';
import { HTP_COMPANIES_TOPIC_ID } from '../../../common/topic-constants';
import SimpleLineChart from '../simple-line-chart';
import HtmlHeader from '../../html/html-header';
// import DevpulseTooltip from '../html/tooltip';

export const HtpCompaniesChart = (props) => {

  const [data, setData] = useState(0);
  const [topicName, setTopicName] = useState(0);
  // const [range, setRange] = useState(-1);

  useEffect(() => {

    fetch(RECORDS_ENDPOINT + `?topicId=${HTP_COMPANIES_TOPIC_ID}&range=${-1}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => response.json())
      .then(data => {
        const { records, topicName } = data;
        const list = [];
        records.forEach(element => {
          list.push({
            xval: element.date, yval: element.value
          })
        });
        setData(list);
        setTopicName(topicName);
      });

  }, []);


  if (!data) {
    return (
      <div className="container-simple-line-chart-mobile simple-line-chart container">
        Загрузка...
      </div>
    )
  }

  const currentValue = data ? data[data.length - 1].yval : '';

  return (
    <div className="simple-line-chart-container container">
      <div className="simple-line-chart__header">
        <div className="simple-line-chart__title">
          <HtmlHeader text={topicName} type="h3" />
        </div>
        <div className="simple-line-chart__current-value-container">
          <div className="simple-line-chart__current-value">
            Количество компаний сейчас: {currentValue}
          </div>
        </div>
      </div>
      <div className="simple-line-chart">
        <SimpleLineChart yTooltipText="компаний" data={data} />
      </div>
    </div>
  );
}

export default HtpCompaniesChart;