import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import './index.css';

const RegisterConfirmEmail = () => {
  const history = useHistory();
  const { location: { state } } = history;
  const { email } = state;
  return (
    <div className='container'>
      <h3>
        Регистрация почти завершена
      </h3>
      <p>
        На электронную почту {email} было отправлено письмо для завершения регистрации.
      </p>
      <p>Пожалуйста, проверьте почту!</p>
      <br />
      <div className='register-confirm-email__link'>
        <NavLink to="/">На главную</NavLink>
      </div>
    </div>
  );
}

export default RegisterConfirmEmail;