import React, { useState, useEffect } from 'react';
import { getRequest } from '../../../common/client';
import { LANGUAGES_TABLE_FULL_DATA_ENDPOINT } from '../../../common/constants';
import HtmlHeader from '../../html/html-header';
import { SimplePieChart } from '../simple-pie-chart';
import './index.scss'

export const LanguagesPieChartContainer = () => {

  const [data, setData] = useState([]);
  const [rawData, setRawData] = useState([]);

  useEffect(() => {

    (async () => {
      try {
        const { data: response, ok } = await getRequest(LANGUAGES_TABLE_FULL_DATA_ENDPOINT, false);

        if (ok) {

          response.sort(item => item.lastValue);
          const showSeparately = response.slice(0, 6);
          const showTogetherTotal = response.slice(6).reduce((partialSum, item) => partialSum + item.lastValue, 0);

          const preparedData = showSeparately.map((item) => {
            return { name: item.topicName, value: item.lastValue };
          });
          preparedData.push({ name: "other", value: showTogetherTotal });
          setData(preparedData);
          setRawData(response);
        }

      } catch (error) {
        console.log(error);
      }
    })();


  }, [])

  return (
    <div className='container languages-pie-chart-container'>
      <HtmlHeader text={"Доминация языков программирования в РБ"} />
      <div className='languages-pie-chart__content'>
        <div className='languages-pie-chart__chart'>
          <SimplePieChart data={data} />
        </div>
        <div className='languages-pie-chart__desc'>
          <HtmlHeader text={"Описание"} type={"h4"} />
          <p>Данный график показывает соотношение количества вакансий по всем языкам программирования</p>
        </div>
      </div>
    </div>
  );
}