import CardLink from "../../../../components/html/card-link";
import HtmlHeader from "../../../../components/html/html-header";
import RelatedPages from '../../../../components/related-pages';
import { AbstractPage } from '../../../abstractPage';
import { developersMobileRelatedList } from '../../../related-lists';

const DevelopersPage = () => {
  return (<AbstractPage>
    <HtmlHeader text="Направления в разработке" type="h1" withMargin={true} />
    <CardLink
      title="Бэкенд / Backend"
      text="Данные про востребованность языков, позиций и технологий"
      url="/path/backend" />
    <CardLink
      title="Фронтенд / Frontend"
      text="Данные про востребованность языков, позиций и технологий"
      url="/path/frontend" />
    <CardLink
      title="Мобильная разработка / Mobile"
      text="Данные про востребованность языков, позиций и технологий"
      url="/path/mobile" />
    <CardLink
      title="Разработка игр / Gamadev"
      text="Данные про востребованность языков, позиций и технологий"
      url="/path/gamedev" />
    <RelatedPages list={developersMobileRelatedList} />
  </AbstractPage>);
}

export default DevelopersPage;