import { AbstractPage } from '../abstractPage';

const LoadingPage = () => {
  return (<AbstractPage>
    <div className="container">
      Загрузка...
    </div >
  </AbstractPage>);
}

export default LoadingPage;