import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { slide as Menu } from 'react-burger-menu'
import './index.css';

var styles = {
  bmBurgerButton: {
    position: 'absolute',
    width: '24px',
    height: '18px',
    right: '20px',
    top: '25px'
  },
  bmBurgerBars: {
    background: 'rgb(245, 245, 245)'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '32px',
    width: '32px',
    top: "10em",
    left: "5px",
    display: "none"
  },
  bmCross: {
    height: "24px",
    background: "rgb(189, 195, 199)"
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: 0,
    width: '250px',
    transition: 'all 0.2s'
  },
  bmMenu: {
    background: 'white',
    padding: '10em 1.5em 0',
    fontSize: '1.1em',
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em'
  },
  bmItem: {
    marginBottom: "20px",
    color: "var(--color-font)"
  },
  bmOverlay: {
    top: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.3)'
  }
}


const MenuMobile = ({ options }) => {
  const history = useHistory();
  const [menuOpen, setMenuOpen] = useState(false);

  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen)
  }

  const handleChange = (url) => {
    history.push(url);
  }

  return (
    <Menu styles={styles}
      right
      disableAutoFocus
      isOpen={menuOpen}
      onStateChange={(state) => handleStateChange(state)}
    >
      {options.map(option => {
        const { label, labelMobile, url } = option;

        if (url) { // render menu item
          const handleMenuItemClick = () => {
            handleChange(url);
            setMenuOpen(false);
          };

          return <div key={url} id={url} className="menu-item" onClick={handleMenuItemClick}>{label}</div>
        } else { // render menu list
          const { list } = option;
          return list.map(item => {
            const { label, labelMobile, url } = item;
            const handleMenuItemClick = () => {
              handleChange(url);
              setMenuOpen(false);
            };
            return <div key={url} id={url} className="menu-item" onClick={handleMenuItemClick}>{labelMobile || label}</div>
          })
        }

      })}
    </Menu>
  )
}

export default MenuMobile;