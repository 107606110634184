import { Link, useHistory } from "react-router-dom";
import HtmlHeader from "../html-header";
import "./index.css";

const CardLink = ({ title, text, url }) => {
  const history = useHistory();
  return (
    <div className="card-link" onClick={() => history.push(url)}>
      <div className="container">
        <div className="card-link__content">
          <HtmlHeader text={title} type="h4" />
          <p>{text}</p>
        </div>
        <div className="card-link__link">
          <Link to={url}>Подробнее</Link>
        </div>
      </div>
    </div>
  );
}

export default CardLink;