import React, { useEffect, useState } from 'react';
import { TOTAL_NUMBER_OF_EMPLOYEES_IN_TRACKED_COMPANIES_PER_ENDPOINT } from '../../../common/constants';
import { getRequest } from '../../../common/client';
import MultipleTopicsLineChartView from '../multiple-topics-line-chart-view';
import { fromStringToDate } from '../../../common/utils';

const ItCompaniesEmployeesPerDateLineChart = (props) => {

  const { title, anchorId } = props;
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      const { ok, data: responseData } = await getRequest(
        TOTAL_NUMBER_OF_EMPLOYEES_IN_TRACKED_COMPANIES_PER_ENDPOINT,
        false
      );

      if (ok) {
        const list = [];

        for (let i = 0; i < responseData.length; i++) {
          const item = responseData[i];
          const { date, by, world } = item;
          const listItem = {
            xval: date,
          };
          listItem["Belarus"] = Math.round(by);
          listItem["World"] = Math.round(world);
          list.push(listItem);
        }

        list.sort((a, b) => fromStringToDate(a.xval).diff(fromStringToDate(b.xval)));
        setData(list);
      }
    })();

  }, []);


  return (
    <MultipleTopicsLineChartView
      title={title}
      anchorId={anchorId}
      data={data}
      topicsResources={[{ name: "World" }, { name: "Belarus" }]}
      desc="Данные только по компаниям в которых работают/работали сотрудники из РБ. Так же необходимо 
      учитывать что почти каждый месяц до конца лета 2022 добавлялись новые компании."
      height={280}
    />
  );
}

export default ItCompaniesEmployeesPerDateLineChart;