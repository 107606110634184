import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MOST_DEMANDED_TOPIC_ENDPOINT } from "../../common/constants";
import './index.css';

const MostDemandedTopic = ({ desc, topics, titleCapitalized = false, learMoreLink }) => {

  const [data, setData] = useState(0);

  useEffect(() => {

    fetch(MOST_DEMANDED_TOPIC_ENDPOINT + "?topics=" + topics, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      return response.json()
    })
      .then(data => {
        setData(data);
      }).catch(function (reason) {
        console.log(reason);
      });

  }, [topics]);


  if (!data) {
    return (<div className="fact container">
      Загрузка...
    </div>);
  }

  return (
    <div className="fact container">
      <div>
        <div className={"fact-title " + (titleCapitalized ? "capitalize-text" : "")}>
          {data.name}
        </div>
        <div className="fact-description">
          {desc}
          {learMoreLink && <div className='fact__lear-more-link'><Link to={learMoreLink}>Подробнее</Link></div>}
        </div>
      </div>
    </div>);
}

export default MostDemandedTopic;