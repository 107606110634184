export const HTML_TOPIC_ID = 1;
export const CSS_TOPIC_ID = 2;
export const JAVASCRIPT_TOPIC_ID = 3;
export const PYTHON_TOPIC_ID = 4;
export const JAVA_TOPIC_ID = 5;
export const CSHARP_TOPIC_ID = 6;
export const TYPESCRIPT_TOPIC_ID = 7;
export const PHP_TOPIC_ID = 8;
export const CPLUSPLUS_TOPIC_ID = 9;
export const GO_TOPIC_ID = 10;
export const KOTLIN_TOPIC_ID = 11;
export const RUBY_TOPIC_ID = 12;
export const SWIFT_TOPIC_ID = 13;
export const RUST_TOPIC_ID = 14;
export const OBJECTIVEC_TOPIC_ID = 15;
export const SCALA_TOPIC_ID = 16;
export const PERL_TOPIC_ID = 17;
export const DELPHI_TOPIC_ID = 19;

export const JQUERY_TOPIC_ID = 20;
export const REACT_TOPIC_ID = 21;
export const ANGULAR_TOPIC_ID = 22;
export const VUE_TOPIC_ID = 26;
export const NET_TOPIC_ID = 24;
export const NET_CORE_TOPIC_ID = 25;
export const SPRING_TOPIC_ID = 27;
export const FLASK_TOPIC_ID = 28;
export const DJANGO_TOPIC_ID = 29;
export const LARAVEL_TOPIC_ID = 30;
export const ROR_TOPIC_ID = 31;
export const SYMFONY_TOPIC_ID = 32;
export const DRUPAL_TOPIC_ID = 33;
export const NODEJS_TOPIC_ID = 34;
export const UNITY_TOPIC_ID = 37;

export const FLUTTER_TOPIC_ID = 39;
export const XAMARIN_TOPIC_ID = 41;
export const APACHE_SPARK_TOPIC_ID = 43;
export const HADOOP_TOPIC_ID = 44;
export const UNREAL_ENGINE_TOPIC_ID = 45;
export const SELENIUM_TOPIC_ID = 46;
export const SELENIUM_WEBDRIVER_TOPIC_ID = 47;

export const MYSQL_TOPIC_ID = 48;
export const POSTGRESQL_TOPIC_ID = 49;
export const SQLSERVER_TOPIC_ID = 50;
export const SQLITE_TOPIC_ID = 51;
export const MONGODB_TOPIC_ID = 52;
export const REDIS_TOPIC_ID = 53;
export const ORACLE_TOPIC_ID = 54;
export const ELASTICSEARCH_TOPIC_ID = 55;
export const DYNAMODB_TOPIC_ID = 56;
export const CASSANDRA_TOPIC_ID = 57;

export const WORDPRESS_DEVELOPER_TOPIC_ID = 58;
export const PHP_DEVELOPER_TOPIC_ID = 59;
export const NET_DEVELOPER_TOPIC_ID = 60;
export const PYTHON_DEVELOPER_TOPIC_ID = 61;
export const JAVA_DEVELOPER_TOPIC_ID = 63;
export const UNITY_DEVELOPER_TOPIC_ID = 64;
export const SALESFORCE_DEVELOPER_TOPIC_ID = 65;
export const ANDROID_DEVELOPER_TOPIC_ID = 66;
export const IOS_DEVELOPER_TOPIC_ID = 67;
export const REACT_NATIVE_DEVELOPER_TOPIC_ID = 68;
export const FLUTTER_DEVELOPER_TOPIC_ID = 69;
export const QA_ENGINEER_TOPIC_ID = 70;

export const SENIOR_JAVA_DEVELOPER_TOPIC_ID = 75;
export const SENIOR_PYTHON_DEVELOPER_TOPIC_ID = 76;
export const SENIOR_CSHAPR_DEVELOPER_TOPIC_ID = 77;
export const SENIOR_GO_DEVELOPER_TOPIC_ID = 78;
export const SENIOR_PHP_DEVELOPER_TOPIC_ID = 79;
export const SENIOR_RUBY_DEVELOPER_TOPIC_ID = 81;
export const SENIOR_NODE_DEVELOPER_TOPIC_ID = 82;
export const SENIOR_ANGULAR_DEVELOPER_TOPIC_ID = 83;
export const SENIOR_ANDROID_DEVELOPER_TOPIC_ID = 84;
export const SENIOR_IOS_DEVELOPER_TOPIC_ID = 85;
export const TEAM_LEAD_TOPIC_ID = 86;
export const DEVOPS_TOPIC_ID = 87;

export const LINUX_TOPIC_ID = 91;
export const DOCKER_TOPIC_ID = 92;
export const KUBERNETES_TOPIC_ID = 93;
export const AWS_TOPIC_ID = 94;
export const AZURE_TOPIC_ID = 95;
export const GOOGLE_CLOUD_PLATFORM_TOPIC_ID = 97;

export const WORDPRESS_TOPIC_ID = 100;
export const MAGENTO_TOPIC_ID = 103;


export const CPLUSPLUS_DEVELOPER_TOPIC_ID = 115
export const REACT_DEVELOPER_TOPIC_ID = 120
export const JUNIOR_JAVA_DEVELOPER_TOPIC_ID = 121
export const JUNIOR_NET_DEVELOPER_TOPIC_ID = 122
export const JUNIOR_NODE_DEVELOPER_TOPIC_ID = 123
export const JUNIOR_PHP_DEVELOPER_TOPIC_ID = 124
export const JUNIOR_CPLUSPLUS_DEVELOPER_TOPIC_ID = 125
export const JUNIOR_GO_DEVELOPER_TOPIC_ID = 126
export const JUNIOR_ANDROID_DEVELOPER_TOPIC_ID = 127
export const JUNIOR_RUBY_DEVELOPER_TOPIC_ID = 128
export const JUNIOR_IOS_DEVELOPER_TOPIC_ID = 129
export const JUNIOR_SCALA_DEVELOPER_TOPIC_ID = 130
export const JUNIOR_REACT_DEVELOPER_TOPIC_ID = 131
export const JUNIOR_ANGULAR_DEVELOPER_TOPIC_ID = 132
export const JUNIOR_VUE_DEVELOPER_TOPIC_ID = 133
export const JUNIOR_UNITY_DEVELOPER_TOPIC_ID = 134
export const JUNIOR_PROJECT_MANAGER_TOPIC_ID = 135
export const JUNIOR_SALESFORCE_DEVELOPER_TOPIC_ID = 136
export const JUNIOR_TEST_AUTOMATION_ENGINEER_TOPIC_ID = 137
export const JUNIOR_QA_ENGINEER_TOPIC_ID = 138
export const JUNIOR_REACT_NATIVE_DEVELOPER_TOPIC_ID = 139
export const JUNIOR_DEVOPS_TOPIC_ID = 140
export const JUNIOR_BUSINESS_ANALYST_TOPIC_ID = 141
export const JUNIOR_RECRUTER_TOPIC_ID = 142
export const SENIOR_NET_DEVELOPER_TOPIC_ID = 143
export const SENIOR_REACT_DEVELOPER_TOPIC_ID = 144

export const MIDDLE_JAVA_DEVELOPER_TOPIC_ID = 145
export const MIDDLE_NET_DEVELOPER_TOPIC_ID = 146
export const MIDDLE_NODE_DEVELOPER_TOPIC_ID = 147
export const MIDDLE_PHP_DEVELOPER_TOPIC_ID = 148
export const MIDDLE_CPLUSPLUS_DEVELOPER_TOPIC_ID = 149
export const MIDDLE_GO_DEVELOPER_TOPIC_ID = 150
export const MIDDLE_ANDROID_DEVELOPER_TOPIC_ID = 151
export const MIDDLE_RUBY_DEVELOPER_TOPIC_ID = 152
export const MIDDLE_IOS_DEVELOPER_TOPIC_ID = 153
export const MIDDLE_SCALA_DEVELOPER_TOPIC_ID = 154
export const MIDDLE_REACT_DEVELOPER_TOPIC_ID = 155
export const MIDDLE_ANGULAR_DEVELOPER_TOPIC_ID = 156
export const MIDDLE_VUE_DEVELOPER_TOPIC_ID = 157
export const MIDDLE_UNITY_DEVELOPER_TOPIC_ID = 158
export const MIDDLE_PROJECT_MANAGER_TOPIC_ID = 159
export const MIDDLE_SALESFORCE_DEVELOPER_TOPIC_ID = 160
export const MIDDLE_TEST_AUTOMATION_ENGINEER_TOPIC_ID = 161
export const MIDDLE_QA_ENGINEER_TOPIC_ID = 162
export const MIDDLE_REACT_NATIVE_DEVELOPER_TOPIC_ID = 163
export const MIDDLE_DEVOPS_TOPIC_ID = 164
export const MIDDLE_BUSINESS_ANALYST_TOPIC_ID = 165
export const MIDDLE_RECRUITER_TOPIC_ID = 166
export const JUNIOR_PYTHON_DEVELOPER_TOPIC_ID = 167
export const MIDDLE_PYTHON_DEVELOPER_TOPIC_ID = 168
export const SENIOR_CPULSPLUS_DEVELOPER_TOPIC_ID = 169
export const SCALA_DEVELOPER_TOPIC_ID = 170
export const SENIOR_SCALA_DEVELOPER_TOPIC_ID = 171
export const RUST_DEVELOPER_TOPIC_ID = 172
export const ANGULAR_DEVELOPER_TOPIC_ID = 173
export const VUE_DEVELOPER_TOPIC_ID = 174
export const SENIOR_VUE_DEVELOPER_TOPIC_ID = 175

export const PYTHON_DEVELOPER_RESUME_TOPIC_ID = 177
export const JAVA_DEVELOPER_RESUME_TOPIC_ID = 178
export const PHP_DEVELOPER_RESUME_TOPIC_ID = 179
export const CPLUSPLUS_DEVELOPER_RESUME_TOPIC_ID = 180
export const GO_DEVELOPER_RESUME_TOPIC_ID = 181
export const ANDROID_DEVELOPER_RESUME_TOPIC_ID = 182
export const RUBY_DEVELOPER_RESUME_TOPIC_ID = 183
export const IOS_DEVELOPER_RESUME_TOPIC_ID = 184
export const SCALA_DEVELOPER_RESUME_TOPIC_ID = 185
export const REACT_DEVELOPER_RESUME_TOPIC_ID = 186
export const ANGULAR_DEVELOPER_RESUME_TOPIC_ID = 187
export const DOTNET_DEVELOPER_RESUME_TOPIC_ID = 188
export const VUE_DEVELOPER_RESUME_TOPIC_ID = 189
export const NODEJS_DEVELOPER_RESUME_TOPIC_ID = 190
export const REACTNATIVE_DEVELOPER_RESUME_TOPIC_ID = 191
export const UNITY_DEVELOPER_RESUME_TOPIC_ID = 192

export const RUBY_DEVELOPER_TOPIC_ID = 193
export const DELPHI_DEVELOPER_TOPIC_ID = 194
export const ONEC_DEVELOPER_TOPIC_ID = 195
export const ONEC_TOPIC_ID = 196
export const HIBERNATE_TOPIC_ID = 197
export const SENIOR_QA_ENGINEER_TOPIC_ID = 198
export const SENIOR_QA_AUTOMATION_ENGINEER_TOPIC_ID = 199
export const GO_DEVELOPER_TOPIC_ID = 200
export const STL_TOPIC_ID = 201
export const BOOST_TOPIC_ID = 202
export const RABBIT_MQ_TOPIC_ID = 203
export const KAFKA_TOPIC_ID = 205
export const JENKINS_TOPIC_ID = 206
export const SERVERLESS_TOPIC_ID = 208
export const HTP_COMPANIES_TOPIC_ID = 209
export const SENIOR_UNITY_DEVELOPER_TOPIC_ID = 210
export const SENIOR_SALESFORCE_DEVELOPER_TOPIC_ID = 211
export const SENIOR_REACT_NATIVE_DEVELOPER_TOPIC_ID = 212




export const BACKEND_FRAMEWORKS_TOPICS_IDS = [NET_TOPIC_ID, SPRING_TOPIC_ID, FLASK_TOPIC_ID, DJANGO_TOPIC_ID, ROR_TOPIC_ID,
  LARAVEL_TOPIC_ID, SYMFONY_TOPIC_ID];

export const DATABASES_TOPICS_IDS = [MYSQL_TOPIC_ID, POSTGRESQL_TOPIC_ID, SQLSERVER_TOPIC_ID, SQLITE_TOPIC_ID,
  MONGODB_TOPIC_ID, REDIS_TOPIC_ID, ORACLE_TOPIC_ID, DYNAMODB_TOPIC_ID, CASSANDRA_TOPIC_ID];

export const CLOUD_PROVIDERS_TOPICS_IDS = [AWS_TOPIC_ID, AZURE_TOPIC_ID, GOOGLE_CLOUD_PLATFORM_TOPIC_ID];