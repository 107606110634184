import { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { IT_STATUS_ENDPOINT } from "../../common/constants";
import './index.css';

const BelarusItJobsStatusNavbarMobile = () => {

  const [data, setData] = useState(0);

  useEffect(() => {

    fetch(IT_STATUS_ENDPOINT, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => response.json())
      .then(data => {
        setData(data);
      });

  }, []);

  if (!data) {
    return (<div className="bel-it-jobs-status-navbar-mobile">
      Загрузка...
    </div>);
  }

  const renderChange = () => {
    const { monthChange } = data;
    const clazzName = monthChange >= 0 ? 'positive' : 'negative';

    const changePercents = Math.abs(monthChange / 100.0);
    if (monthChange >= 0) {
      return (
        <span className={clazzName}>
          рост на {changePercents} % за месяц
        </span>)
    } else {
      return (
        <span className={clazzName}>
          падение на {changePercents} % за месяц
        </span>)
    }
  }

  return (
    <div className="bel-it-jobs-status-navbar-mobile">
      <span className="bel-it-jobs-status-navbar-mobile__text">Количество вакансий в IT: </span>
      {renderChange()}
    </div>
  );
}

export default BelarusItJobsStatusNavbarMobile;