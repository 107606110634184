import { useEffect } from 'react';
import {
  JAVASCRIPT_TOPIC_ID,
  PYTHON_TOPIC_ID,
  JAVA_TOPIC_ID,
  CSHARP_TOPIC_ID,
  TYPESCRIPT_TOPIC_ID,
  PHP_TOPIC_ID,
  CPLUSPLUS_TOPIC_ID,
  GO_TOPIC_ID,
  KOTLIN_TOPIC_ID,
  RUBY_TOPIC_ID,
  SWIFT_TOPIC_ID,
  // RUST_TOPIC_ID,
  OBJECTIVEC_TOPIC_ID,
  SCALA_TOPIC_ID,
  // PERL_TOPIC_ID,
  DELPHI_TOPIC_ID
} from "../../common/topic-constants";
import HtmlHeader from "../../components/html/html-header";
import TopicChartContainer from "../../components/charts/topic-chart-container";
import { AbstractPage } from '../abstractPage';
import { LANGUAGES_TABLE_FULL_DATA_ENDPOINT } from '../../common/constants';
import { Accordion, AccordionItem, AccordionItemPanel, AccordionItemHeading, AccordionItemButton } from 'react-accessible-accordion';
import PageUpdated from '../../components/page-updated';
import { JobsTable } from '../../components/tables/jobs-table';
import RelatedPages from '../../components/related-pages';
import { topLanguagesRelatedList } from '../related-lists';

const TopLanguagesPage = () => {

  useEffect(() => {
    document.title = "Востребованность языков программирования | Devpulse.by — аналитика рынка IT вакансий в Беларуси";
  }, []);

  return (
    <AbstractPage>
      <PageUpdated withMargin={true} />
      <HtmlHeader text="Востребованность языков программирования в IT Беларуси" type="h1" withMargin={true} />
      <JobsTable
        showFullTable={true}
        showTitle={false}
        apiUrl={LANGUAGES_TABLE_FULL_DATA_ENDPOINT}
        rowsClickable={true}
        rowLinkBase="/languages/"
        rowLinkParamName="topicId"
        showPlace={true}
        showWeekChange={true}
        showMonthChange={true}
      />
      <Accordion allowZeroExpanded>
        <AccordionItem>
          <AccordionItemHeading style={{
            display: "flex",
            justifyContent: "center"
          }}>
            <AccordionItemButton className="devpulse-button" style={{
              width: "400px",
            }}>
              Нажмите сюда чтобы смотреть подробную информацию о языках
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <TopicChartContainer topicId={JAVASCRIPT_TOPIC_ID} withLink={true} />
            <TopicChartContainer topicId={JAVA_TOPIC_ID} withLink={true} />
            <TopicChartContainer topicId={PYTHON_TOPIC_ID} withLink={true} />
            <TopicChartContainer topicId={CSHARP_TOPIC_ID} withLink={true} />
            <TopicChartContainer topicId={PHP_TOPIC_ID} withLink={true} />
            <TopicChartContainer topicId={CPLUSPLUS_TOPIC_ID} withLink={true} />
            <TopicChartContainer topicId={GO_TOPIC_ID} withLink={true} />
            <TopicChartContainer topicId={TYPESCRIPT_TOPIC_ID} withLink={true} />
            <TopicChartContainer topicId={KOTLIN_TOPIC_ID} withLink={true} />
            <TopicChartContainer topicId={SWIFT_TOPIC_ID} withLink={true} />
            <TopicChartContainer topicId={OBJECTIVEC_TOPIC_ID} withLink={true} />
            <TopicChartContainer topicId={RUBY_TOPIC_ID} withLink={true} />
            <TopicChartContainer topicId={SCALA_TOPIC_ID} withLink={true} />
            <TopicChartContainer topicId={DELPHI_TOPIC_ID} withLink={true} />
            {/* <TopicChartContainer topicId={RUST_TOPIC_ID} /> */}
            {/* <TopicChartContainer topicId={PERL_TOPIC_ID} /> */}
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
      <p></p>
      <RelatedPages list={topLanguagesRelatedList} />
    </AbstractPage>
  );
}

export default TopLanguagesPage;