import { useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {
  ANDROID_DEVELOPER_RESUME_TOPIC_ID,
  ANGULAR_DEVELOPER_RESUME_TOPIC_ID, CPLUSPLUS_DEVELOPER_RESUME_TOPIC_ID,
  DOTNET_DEVELOPER_RESUME_TOPIC_ID, GO_DEVELOPER_RESUME_TOPIC_ID,
  IOS_DEVELOPER_RESUME_TOPIC_ID, JAVA_DEVELOPER_RESUME_TOPIC_ID,
  NODEJS_DEVELOPER_RESUME_TOPIC_ID, PHP_DEVELOPER_RESUME_TOPIC_ID,
  PYTHON_DEVELOPER_RESUME_TOPIC_ID, REACTNATIVE_DEVELOPER_RESUME_TOPIC_ID,
  REACT_DEVELOPER_RESUME_TOPIC_ID, RUBY_DEVELOPER_RESUME_TOPIC_ID,
  SCALA_DEVELOPER_RESUME_TOPIC_ID, UNITY_DEVELOPER_RESUME_TOPIC_ID,
  VUE_DEVELOPER_RESUME_TOPIC_ID
} from '../../common/topic-constants';
import TopicChartContainer from '../../components/charts/topic-chart-container';
import DevpulseAlert from '../../components/html/alert';
import HtmlHeader from "../../components/html/html-header";
import { AbstractPage } from '../abstractPage';

const ResumesPage = () => {

  useEffect(() => {
    document.title = "Количество резюме в айти | Devpulse.by — аналитика рынка IT вакансий в Беларуси";
  }, []);

  return (<AbstractPage>
    <HtmlHeader text="Количество резюме для разных языков и технологий в РБ" type="h1" withMargin={true} />
    <DevpulseAlert
      type="warning"
      message="Информация на этой странице помогает понять примерное количество людей, которые находятся в поиске работы на данный момент"
    />
    <br></br>
    <div>
      <Tabs>
        <TabList>
          <Tab>Backend</Tab>
          <Tab>Frontend</Tab>
          <Tab>Mobile</Tab>
          <Tab>GameDev</Tab>
        </TabList>
        <TabPanel>
          <TopicChartContainer topicId={PYTHON_DEVELOPER_RESUME_TOPIC_ID} type="resumes" period="MONTH" />
          <TopicChartContainer topicId={JAVA_DEVELOPER_RESUME_TOPIC_ID} type="resumes" period="MONTH" />
          <TopicChartContainer topicId={PHP_DEVELOPER_RESUME_TOPIC_ID} type="resumes" period="MONTH" />
          <TopicChartContainer topicId={CPLUSPLUS_DEVELOPER_RESUME_TOPIC_ID} type="resumes" period="MONTH" />
          <TopicChartContainer topicId={GO_DEVELOPER_RESUME_TOPIC_ID} type="resumes" period="MONTH" />
          <TopicChartContainer topicId={RUBY_DEVELOPER_RESUME_TOPIC_ID} type="resumes" period="MONTH" />
          <TopicChartContainer topicId={SCALA_DEVELOPER_RESUME_TOPIC_ID} type="resumes" period="MONTH" />
          <TopicChartContainer topicId={DOTNET_DEVELOPER_RESUME_TOPIC_ID} type="resumes" period="MONTH" />
          <TopicChartContainer topicId={NODEJS_DEVELOPER_RESUME_TOPIC_ID} type="resumes" period="MONTH" />
        </TabPanel>
        <TabPanel>
          <TopicChartContainer topicId={REACT_DEVELOPER_RESUME_TOPIC_ID} type="resumes" period="MONTH" />
          <TopicChartContainer topicId={ANGULAR_DEVELOPER_RESUME_TOPIC_ID} type="resumes" period="MONTH" />
          <TopicChartContainer topicId={VUE_DEVELOPER_RESUME_TOPIC_ID} type="resumes" period="MONTH" />
        </TabPanel>
        <TabPanel>
          <TopicChartContainer topicId={ANDROID_DEVELOPER_RESUME_TOPIC_ID} type="resumes" period="MONTH" />
          <TopicChartContainer topicId={IOS_DEVELOPER_RESUME_TOPIC_ID} type="resumes" period="MONTH" />
          <TopicChartContainer topicId={REACTNATIVE_DEVELOPER_RESUME_TOPIC_ID} type="resumes" period="MONTH" />
        </TabPanel>
        <TabPanel>
          <TopicChartContainer topicId={UNITY_DEVELOPER_RESUME_TOPIC_ID} type="resumes" period="MONTH" />
        </TabPanel>
      </Tabs>
    </div>

  </AbstractPage>);
}

export default ResumesPage;