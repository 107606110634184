import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IT_STATUS_ENDPOINT } from "../../common/constants";
import './index.css';

const BelarusItJobsStatusNavbarDesktop = () => {

  const [data, setData] = useState(0);

  useEffect(() => {

    fetch(IT_STATUS_ENDPOINT, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      return response.json()
    })
      .then(data => {
        setData(data);
      }).catch(function (reason) {
        console.log(reason);
      });

  }, []);

  if (!data) {
    return (<div className="bel-it-jobs-status-navbar-desktop">
      Загрузка...
    </div>);
  }

  const renderChange = () => {
    const { monthChange } = data;
    const clazzName = monthChange >= 0 ? 'positive' : 'negative';

    const changePercents = Math.abs(monthChange / 100.0);
    if (monthChange >= 0) {
      return (
        <div className={clazzName}>
          рост на {changePercents} %
        </div>)
    } else {
      return (
        <div className={clazzName}>
          падение на {changePercents} %
        </div>)
    }
  }

  return (
    <Link to="/">
      <div className="bel-it-jobs-status-navbar-desktop">
        <div className="bel-it-jobs-status-navbar-desktop__text">Количество вакансий в IT:</div>
        {renderChange()}
      </div>
    </Link>);
}

export default BelarusItJobsStatusNavbarDesktop;