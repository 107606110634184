import dayjs from 'dayjs'

export const formatDate = (date) => {
  return dayjs(date, "YYYY-MM-DD").format('D MMMM YYYY');
}

export const formatDate2 = (date) => {
  return dayjs(date, "YYYY-MM-DD").format('D MMM YYYY');
}

export const formatDateAndTime = (date) => {
  return dayjs(date, "YYYY-MM-DD HH:mm:ss").format('D MMMM YYYY HH:m:ss');
}


export const fromStringToDate = (stringDate) => {
  return dayjs(stringDate, "YYYY-MM-DD");
}