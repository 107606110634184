import {
  APACHE_SPARK_TOPIC_ID,
  HADOOP_TOPIC_ID,
  JUNIOR_SCALA_DEVELOPER_TOPIC_ID,
  KAFKA_TOPIC_ID,
  MIDDLE_SCALA_DEVELOPER_TOPIC_ID,
  RABBIT_MQ_TOPIC_ID,
  SCALA_DEVELOPER_TOPIC_ID,
  SCALA_TOPIC_ID,
  SENIOR_SCALA_DEVELOPER_TOPIC_ID,
} from "../../../common/topic-constants";
import HtmlHeader from "../../../components/html/html-header";
import TopicChartContainer from '../../../components/charts/topic-chart-container';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { AbstractPage } from '../../abstractPage';

const ScalaPage = () => {
  return (<AbstractPage>
    <HtmlHeader text="Страница языка Scala" type="h1" withMargin={true} />
    <TopicChartContainer topicId={SCALA_TOPIC_ID} />
    <Tabs>
      <TabList>
        <Tab>Позиции</Tab>
        <Tab>Библиотеки</Tab>
      </TabList>
      <TabPanel>
        <HtmlHeader text="Позиции для Scala разработчиков:" type="h2" withMargin={true} />
        <TopicChartContainer topicId={SCALA_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={JUNIOR_SCALA_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={MIDDLE_SCALA_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={SENIOR_SCALA_DEVELOPER_TOPIC_ID} />
      </TabPanel>
      <TabPanel>
        <HtmlHeader text="Связанное со Scala:" type="h2" withMargin={true} />
        <TopicChartContainer topicId={KAFKA_TOPIC_ID} />
        <TopicChartContainer topicId={HADOOP_TOPIC_ID} />
        <TopicChartContainer topicId={APACHE_SPARK_TOPIC_ID} />
        <TopicChartContainer topicId={RABBIT_MQ_TOPIC_ID} />
      </TabPanel>
    </Tabs>
  </AbstractPage>);
}

export default ScalaPage;