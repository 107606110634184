import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { formatDate2 } from '../../../common/utils';
import HtmlHeader from '../../html/html-header';
import './index.scss';

const colors = ["#0b84a5", "#f6c85f", "#6f4e7c", "#9dd866", "#ca472f", "#ffa056",
  "#a09ed6", "#3f647e", "#688fad", "#9fc1d3",
  "00bob2", "#52ccce", "#95d47a", "#677c8a", "#b2a296", "#f68fa0", "#8dddd0", "#ef3e5b", "#4b256d", "#6f5495"]

const MultipleTopicsLineChartView = (props) => {
  const { title, anchorId, data, topicsResources, desc, height } = props;

  if (!data || !topicsResources) {
    return (
      <div className="container-simple-line-chart-mobile simple-line-chart">
        Загрузка...
      </div>
    )
  }

  const calcExtraValue = (value) => {

    if (value > 20) {
      return value * 0.2;
    } else {
      return 3;
    }

  };

  // rechart's chart does not correctly display some topics
  // have to calc min and max values manually
  const minValue = Math.min.apply(Math, data.map(item => item.yval));
  const maxValue = Math.max.apply(Math, data.map(item => item.yval));

  const startValue = Math.round(minValue - calcExtraValue(minValue));
  const endValue = Math.round(maxValue + calcExtraValue(maxValue));

  const chartHeight = height || 400;

  return (
    <div id={anchorId} className="topic-chart-container">
      <div>
        <HtmlHeader text={title} type="h3" />
        <div>
          {desc}
        </div>
      </div>
      <div className="multiple-topics-line-chart" style={{ height: chartHeight }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{ top: 15, right: 10, bottom: 0, left: 10 }}>
            {topicsResources && topicsResources.map(({ name }, index) => {
              return (
                <Line key={name} type="monotone" stroke={colors[index]} strokeWidth={1.5} dataKey={name} name={name} dot={false} />
              );
            })}
            <CartesianGrid stroke="#ccc" strokeDasharray="1 4" />
            <XAxis dataKey="xval"
              axisLine={false}
              tickLine={false}
              style={{
                fontSize: '0.7rem',
              }}
              tickFormatter={(val) => {
                return formatDate2(val);
              }}
            />
            <YAxis
              // tick={false}
              hide={true}
              axisLine={false}
              tickLine={false}
              tickCount={6}
              width={20}
              // domain={[startValue, endValue]}
              domain={[0, 600]}
              style={{
                fontSize: '0.6rem',
              }} />

            <Tooltip
              wrapperClassName='multiple-topics-line-chart__tooltip-wrapper'
              itemStyle={{
                padding: "1px"
              }}
            />
            <Legend />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div >
  );
}

export default MultipleTopicsLineChartView;