import { useState, useCallback } from 'react';
import { notification } from 'antd';
import { putRequest } from '../../../common/client';
import { COURSES_ENDPOINT } from '../../../common/constants';

const initialState = {
  isLoading: false,
  errors: null,
}

export const useUpdateCourse = () => {
  const [state, setState] = useState(initialState);

  const call = useCallback(async ({ courseId, body }) => {
    try {
      setState({ errors: null });

      const { data, status, ok } = await putRequest(COURSES_ENDPOINT + "/" + courseId, body, true);

      if (ok) {
        setState({
          errors: null
        });

        notification['success']({
          message: null,
          description: 'Изменения успешно сохранены',
          duration: 5
        });
      } else if (status === 400) {
        const { errors } = data;
        setState({
          errors
        });
      } else if (status === 404) {
        const { message } = data;
        setState({
          errors: message
        });
      }

    } catch (e) {
      console.log(e);
      setState({
        errors: 'Произошла ошибка во время сохранения изменений'
      });
    }

  }, []);

  return [call, state.errors];
}