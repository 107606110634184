import React, { useState, useEffect } from 'react';
import { XAxis, YAxis, ResponsiveContainer, Bar, BarChart as RechartBarChart } from 'recharts';
import { getRequest } from '../../common/client';
import { TABLE_DATA_ENDPOINT } from '../../common/constants';
import HtmlHeader from '../html/html-header';
import './index.css';

export const BarChart = (props) => {
  const { url, title, topics, numberOfTopics, anchorId } = props;
  const [data, setData] = useState(0);

  useEffect(() => {

    (async () => {
      const { ok, data: responseData } = await getRequest(TABLE_DATA_ENDPOINT + "?topics=" + topics.join(","), false);

      if (ok) {
        const list = []
        responseData.forEach(element => {
          list.push({
            xval: element.topicName, yval: element.lastValue
          })
        });
        setData(list);
      }
    })();

  }, [url, topics]);


  if (!data) {
    return (
      <div className="bar-chart-container container">
        Загрузка...
      </div>
    )
  }

  return (
    <div id={anchorId} className="bar-chart-container container">
      <div className="bar-chart__header">
        <div>
          <HtmlHeader text={title} type="h3" />
        </div>
      </div>
      <div className="bar-chart">
        <ResponsiveContainer
          width="100%"
          height={55 * numberOfTopics}
        >
          <RechartBarChart
            layout="vertical"
            height="100%"
            margin={{ top: 5, right: 40, bottom: 5, left: 5 }}
            data={data}

          >
            <XAxis
              type="number"
              axisLine={false}
              stroke='#8884d8'
              domain={[0, 10]}
              ticks={[0, 2.5, 5, 7.5, 10]}
              strokeWidth={0.5}
              tickLine={false}
              tick={false}
            />
            <YAxis
              type="category"
              dataKey="xval"
              width={90}
              // hide={true}
              // tick={false}
              axisLine={false}
              tickLine={false}
            />
            <Bar
              dataKey="yval"
              animationDuration={1000}
              barSize={30}
              fill="#3582e6"
              label={{
                position: 'right',
              }}
            >
            </Bar>
          </RechartBarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default BarChart;