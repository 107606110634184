import {
  QA_ENGINEER_TOPIC_ID,
  JUNIOR_QA_ENGINEER_TOPIC_ID,
  MIDDLE_QA_ENGINEER_TOPIC_ID,
  SENIOR_QA_ENGINEER_TOPIC_ID
} from '../../../../../common/topic-constants';
import HtmlHeader from "../../../../../components/html/html-header";
import TopicChartContainer from "../../../../../components/charts/topic-chart-container";
import { AbstractPage } from '../../../../abstractPage';
import RelatedPages from '../../../../../components/related-pages';
import { manualTestingRelatedList } from '../../../../related-lists';


const QaEngineerPage = () => {
  return (<AbstractPage>
    <HtmlHeader text="QA / Мануальное тестирование. Востребованность позиций и технологий" type="h1" withMargin={true} />
    <TopicChartContainer topicId={QA_ENGINEER_TOPIC_ID} />
    <HtmlHeader text="Востребованность позиций по опыту" type="h2" withMargin={true} />
    <TopicChartContainer topicId={JUNIOR_QA_ENGINEER_TOPIC_ID} />
    <TopicChartContainer topicId={MIDDLE_QA_ENGINEER_TOPIC_ID} />
    <TopicChartContainer topicId={SENIOR_QA_ENGINEER_TOPIC_ID} />
    <RelatedPages list={manualTestingRelatedList} />
  </AbstractPage>);
}

export default QaEngineerPage;