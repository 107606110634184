import React from 'react';
import Register from '../../components/user/register';
import { AbstractPage } from '../abstractPage';

const RegisterPage = () => {

  return (
    <AbstractPage>
      <Register />
    </AbstractPage>
  );
}

export default RegisterPage;