import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { AbstractPage } from '../../abstractPage';
import {
  JUNIOR_ANDROID_DEVELOPER_TOPIC_ID,
  JUNIOR_ANGULAR_DEVELOPER_TOPIC_ID,
  JUNIOR_BUSINESS_ANALYST_TOPIC_ID,
  JUNIOR_CPLUSPLUS_DEVELOPER_TOPIC_ID,
  JUNIOR_DEVOPS_TOPIC_ID,
  JUNIOR_IOS_DEVELOPER_TOPIC_ID, JUNIOR_JAVA_DEVELOPER_TOPIC_ID,
  JUNIOR_NET_DEVELOPER_TOPIC_ID, JUNIOR_PHP_DEVELOPER_TOPIC_ID,
  JUNIOR_PROJECT_MANAGER_TOPIC_ID, JUNIOR_PYTHON_DEVELOPER_TOPIC_ID, JUNIOR_QA_ENGINEER_TOPIC_ID,
  JUNIOR_REACT_DEVELOPER_TOPIC_ID, JUNIOR_REACT_NATIVE_DEVELOPER_TOPIC_ID,
  JUNIOR_RECRUTER_TOPIC_ID, JUNIOR_RUBY_DEVELOPER_TOPIC_ID,
  JUNIOR_SALESFORCE_DEVELOPER_TOPIC_ID, JUNIOR_SCALA_DEVELOPER_TOPIC_ID,
  JUNIOR_TEST_AUTOMATION_ENGINEER_TOPIC_ID, JUNIOR_UNITY_DEVELOPER_TOPIC_ID,
  JUNIOR_VUE_DEVELOPER_TOPIC_ID
} from '../../../common/topic-constants';
import TopicChartContainer from '../../../components/charts/topic-chart-container';
import HtmlHeader from '../../../components/html/html-header';

export const JuniorPositionsPage = () => {
  return (<AbstractPage>
    <HtmlHeader text="Востребованность Junior специалистов" type="h1" withMargin={true} />

    <div>
      <Tabs>
        <TabList>
          <Tab>Backend</Tab>
          <Tab>Frontend</Tab>
          <Tab>Mobile</Tab>
          <Tab>GameDev</Tab>
          <Tab>Testing</Tab>
          <Tab>Management</Tab>
          <Tab>Recruting</Tab>
        </TabList>
        <TabPanel>
          <TopicChartContainer topicId={JUNIOR_JAVA_DEVELOPER_TOPIC_ID} />
          <TopicChartContainer topicId={JUNIOR_NET_DEVELOPER_TOPIC_ID} />
          <TopicChartContainer topicId={JUNIOR_CPLUSPLUS_DEVELOPER_TOPIC_ID} />
          {/* <TopicChartContainer topicId={JUNIOR_GO_DEVELOPER_TOPIC_ID} /> */}
          <TopicChartContainer topicId={JUNIOR_PHP_DEVELOPER_TOPIC_ID} />
          <TopicChartContainer topicId={JUNIOR_RUBY_DEVELOPER_TOPIC_ID} />
          <TopicChartContainer topicId={JUNIOR_SALESFORCE_DEVELOPER_TOPIC_ID} />
          <TopicChartContainer topicId={JUNIOR_DEVOPS_TOPIC_ID} />
          <TopicChartContainer topicId={JUNIOR_SCALA_DEVELOPER_TOPIC_ID} />
          <TopicChartContainer topicId={JUNIOR_PYTHON_DEVELOPER_TOPIC_ID} />

        </TabPanel>
        <TabPanel>
          <TopicChartContainer topicId={JUNIOR_REACT_DEVELOPER_TOPIC_ID} />
          <TopicChartContainer topicId={JUNIOR_ANGULAR_DEVELOPER_TOPIC_ID} />
          <TopicChartContainer topicId={JUNIOR_VUE_DEVELOPER_TOPIC_ID} />
          <TopicChartContainer topicId={JUNIOR_UNITY_DEVELOPER_TOPIC_ID} />
        </TabPanel>
        <TabPanel>
          <TopicChartContainer topicId={JUNIOR_ANDROID_DEVELOPER_TOPIC_ID} />
          <TopicChartContainer topicId={JUNIOR_IOS_DEVELOPER_TOPIC_ID} />
          <TopicChartContainer topicId={JUNIOR_REACT_NATIVE_DEVELOPER_TOPIC_ID} />
        </TabPanel>
        <TabPanel>
          <TopicChartContainer topicId={JUNIOR_UNITY_DEVELOPER_TOPIC_ID} />
        </TabPanel>
        <TabPanel>
          <TopicChartContainer topicId={JUNIOR_TEST_AUTOMATION_ENGINEER_TOPIC_ID} />
          <TopicChartContainer topicId={JUNIOR_QA_ENGINEER_TOPIC_ID} />
        </TabPanel>
        <TabPanel>
          <TopicChartContainer topicId={JUNIOR_PROJECT_MANAGER_TOPIC_ID} />
          <TopicChartContainer topicId={JUNIOR_BUSINESS_ANALYST_TOPIC_ID} />
        </TabPanel>
        <TabPanel>
          <TopicChartContainer topicId={JUNIOR_RECRUTER_TOPIC_ID} />

        </TabPanel>
      </Tabs>
    </div>

  </AbstractPage >);
}