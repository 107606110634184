export const COURSE_CONTACT_TYPES = [
  { type: 'EMAIL', label: 'Емейл' },
  { type: 'LINK', label: 'Ссылка' },
  { type: 'PHONE', label: 'Телефон' },
  { type: 'OTHER', label: 'Другое' }
];

export const COURSE_TYPE_RULES = [
  { required: true, message: 'Укажите тип' },
]

export const COURSE_NAME_RULES = [
  { required: true, message: 'Введите название' },
  { min: 10, message: 'Слишком короткое название' },
  { max: 255, message: 'Слишком длинное название' },
]

export const COURSE_DESC_RULES = [
  { required: true, message: 'Введите краткое описание' },
  { min: 10, message: 'Слишком короткое описание' },
  { max: 250, message: 'Слишком длинное описание' }
]

export const COURSE_DESC_FULL_RULES = [
  { required: true, message: 'Введите полное описание' },
  { min: 10, message: 'Слишком короткое описание' },
  { max: 5000, message: 'Слишком длинное описание' }
]

export const COURSE_TAG_RULES = [
  { required: true, message: 'Выберите направление' }
]

export const COURSE_COUNTRIES_RULES = [
]


export const COURSE_COUNTRY_TOOLTIP_MESSAGE = "Если страна не важна, поле можно оставить пустым"