import React from 'react';
import { Link } from 'react-router-dom';
import { PROFILE_PAGE } from '../../../common/constants';

export const AddCourseSuccess = () => {
  return (
    <div className='container'>
      <h3>Курс успешно создан!</h3>
      <div>
        Сейчас он виден только вам. Чтобы у вас появилась возможность сделать его видимым для всех, нужно чтобы курс прошел модерацию. Отправить курс на модерацию можно в вашем профиле, во вкладке "Курсы"
      </div>
      <br></br>
      <div>
        <Link to={PROFILE_PAGE}>Перейти на страницу вашего профиля</Link>
      </div>
    </div>
  );
}