import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import AuthenticationContext from '../auth-context/intex';
import { LOGIN_PAGE } from '../constants';

const PrivateRoute = ({ children, ...rest }) => {

  const { isAuthenticated } = useContext(AuthenticationContext);

  return (
    <Route {...rest} render={({ location }) => {
      return isAuthenticated() === true
        ? children
        : <Redirect to={{
          pathname: LOGIN_PAGE,
          state: { from: location }
        }} />
    }} />
  )
}

export default PrivateRoute;