import BelarusItJobsStatus from "../components/bel-it-jobs-status";
import CardLink from "../components/html/card-link";
import { MainPageIntro } from '../components/html/main-page-intro';
import { AbstractPage } from './abstractPage';
import {
  COMPANIES_EMPLOYEES_TOP_5_TABLE_DATA_ENDPOINT,
  juniorJobsTopicsIds,
  LANGUAGES_TABLE_TOP5_DATA_ENDPOINT
} from '../common/constants';
import HtpCompaniesChart from '../components/charts/htp-companies-chart';
import MostDemandedTopic from '../components/most-demanded-topic';
import TwoInOneWrapper from '../components/html/two-in-one-wrapper';
import { JobsTable } from '../components/tables/jobs-table';
import { TopItCompaniesByEmployeesTable } from '../components/tables/top-it-companies-by-employees';
import { LanguagesPieChartContainer } from '../components/charts/languages-pie-chart-container';
import { LANGUAGES_TOP_PAGE } from '../common/relative-links';

const MainPage = () => {
  return (<AbstractPage>
    <MainPageIntro />
    <BelarusItJobsStatus />

    <JobsTable
      title="Топ языков программирования"
      showTitle={true}
      showFullTable={false}
      apiUrl={LANGUAGES_TABLE_TOP5_DATA_ENDPOINT}
      rowsClickable={false}
      rowLinkParamName="topicId"
      showPlace={true}
      showWeekChange={false}
      showMonthChange={true}
    />

    <TopItCompaniesByEmployeesTable
      showTitle={true}
      showFullTable={false}
      apiUrl={COMPANIES_EMPLOYEES_TOP_5_TABLE_DATA_ENDPOINT}
      rowsClickable={false}
    />

    <CardLink
      title="Сравнение востребованности различных технологий"
      text="Про разработчиков, фреймворков, баз данных и др."
      url="/demand-in-it/various-technologies" />

    <CardLink
      title="Направление в IT"
      text="Данные о востребованности фронтенд, бекенд, мобильной разработки, мануального и автоматизированного тестирования"
      url="/path" />

    <TwoInOneWrapper
      componentOne={
        <HtpCompaniesChart />
      }
      componentTwo={
        <div>
          <MostDemandedTopic
            topics={juniorJobsTopicsIds.join(",")}
            desc="самое больше количество Junior вакансий на данный момент"
            titleCapitalized={true}
            learMoreLink="/positions/juniors"
          />
          {/* <Fact
            topics={frontendFrameworksTopicsIds.join(",")}
            desc="самый востребованный фреймворк среди фронтенд разработчиков" /> */}
          <CardLink
            title="Позиции"
            text="Данные о востребованности Junior, Middle, Senior позиций"
            url="/positions" />
        </div>
      }
    />

    {/* <BarChart title="Востребованность бекенд фреймворков" url={FRAMEWORKS_BAR_CHART_DATA_ENDPOINT} numberOfTopics={7} /> */}

    <CardLink
      title="Языки программирования"
      text="Подробная информация о востребованности языков программирования"
      url={LANGUAGES_TOP_PAGE} />

    <LanguagesPieChartContainer />

  </AbstractPage>);

}

export default MainPage;