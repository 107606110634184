import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/ru';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { AuthenticationProvider } from './common/auth-context/intex';
import './react-tabs.css';
import 'antd/lib/form/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/button/style/css';
import 'antd/lib/alert/style/css';
import 'antd/lib/select/style/css';
import 'antd/lib/input-number/style/css';
import 'antd/lib/popover/style/css';
import 'antd/lib/menu/style/css';
import 'antd/lib/tabs/style/css';
import 'antd/lib/modal/style/css';
import 'antd/lib/notification/style/css';
import 'antd/lib/style/';
import './index.scss';
import './override-antd.css';

dayjs.extend(customParseFormat);
dayjs.locale('ru');

ReactDOM.render(
  <React.StrictMode>
    <AuthenticationProvider>
      <Router>
        <App />
      </Router>
    </AuthenticationProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
