import { useEffect, useState } from 'react';
import { LATEST_RECORD_DATE_ENDPOINT } from '../../common/constants';
import { formatDate } from '../../common/utils';
import "./index.css";

const PageUpdated = ({ withMargin }) => {

  const [date, setDate] = useState('');

  useEffect(() => {
    fetch(LATEST_RECORD_DATE_ENDPOINT, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => response.json())
      .then(data => {
        const { date } = data;
        setDate(formatDate(date));
      });
  })

  return (<div className={"page-updated" + (withMargin ? " header-margin" : "")}>
    {date ? "обновлено " + date : ""}
  </div >);
}

export default PageUpdated;