import { AddCourseSuccess } from '../../../components/courses/add-course-success';
import { AbstractPage } from '../../abstractPage';

const AddCourseSuccessPage = () => {

  return (<AbstractPage>
    <AddCourseSuccess />
  </AbstractPage>);
}

export default AddCourseSuccessPage;