import React from 'react';
import { AbstractPage } from '../abstractPage';
const Profile = React.lazy(() => import('../../components/user/profile'));

const ProfilePage = () => {

  return (
    <AbstractPage>
      <Profile />
    </AbstractPage>
  );
}

export default ProfilePage;