import CardLink from "../../../../components/html/card-link";
import HtmlHeader from "../../../../components/html/html-header";
import { AbstractPage } from '../../../abstractPage';

const TestingPage = () => {
  return (<AbstractPage>
    <HtmlHeader text="Направления в тестировании" type="h1" withMargin={true} />
    <CardLink
      title="Мануальное тестирование / Manual testing"
      text="Информация о мануальном (ручном) тестировании"
      url="/path/testing/qa-engineer" />
    <CardLink
      title="Автоматизированное тестирование / Automated testing"
      text="Информация о направлении автоматизированного тестирования"
      url="/path/testing/test-automation-engineer" />
  </AbstractPage>);
}

export default TestingPage;