import { AbstractPage } from '../../abstractPage';

const LanguageNotReadyYetPage = () => {
  return (
    <AbstractPage>
      <div className="container">
        <p>Упс... похоже графики для этого языка еще не готовы</p>
      </div>
    </AbstractPage>);
}

export default LanguageNotReadyYetPage;