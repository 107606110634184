import {
  AWS_TOPIC_ID,
  DEVOPS_TOPIC_ID,
  DOCKER_TOPIC_ID,
  GOOGLE_CLOUD_PLATFORM_TOPIC_ID,
  JENKINS_TOPIC_ID,
  KUBERNETES_TOPIC_ID,
  LINUX_TOPIC_ID,
  SERVERLESS_TOPIC_ID,
} from "../../../../common/topic-constants";
import HtmlHeader from "../../../../components/html/html-header";
import TopicChartContainer from '../../../../components/charts/topic-chart-container';
import { AbstractPage } from '../../../abstractPage';
import RelatedPages from '../../../../components/related-pages';
import { devopsRelatedList } from '../../../related-lists';
import { useEffect } from 'react';

const DevopsPage = () => {

  useEffect(() => {
    document.title = "Востребованность позиции Devops и технологий | Devpulse.by — аналитика рынка IT вакансий в Беларуси";
  }, []);

  return (<AbstractPage>
    <HtmlHeader text="Востребованность позиции и технологий из Devops направления" type="h1" withMargin={true} />
    <TopicChartContainer topicId={DEVOPS_TOPIC_ID} />
    <TopicChartContainer topicId={KUBERNETES_TOPIC_ID} />
    <TopicChartContainer topicId={AWS_TOPIC_ID} />
    <TopicChartContainer topicId={DOCKER_TOPIC_ID} />
    <TopicChartContainer topicId={GOOGLE_CLOUD_PLATFORM_TOPIC_ID} />
    <TopicChartContainer topicId={LINUX_TOPIC_ID} />
    <TopicChartContainer topicId={JENKINS_TOPIC_ID} />
    <TopicChartContainer topicId={SERVERLESS_TOPIC_ID} />
    <RelatedPages list={devopsRelatedList} />
  </AbstractPage>);
}

export default DevopsPage;