import HtmlHeader from "../../html-header";

const DirectionDefinition = ({ title, text }) => {
  return (
    <div className="container">
      <HtmlHeader text={title} type="h3" />
      <p>
        {text}
      </p>
    </div>
  );
}

export default DirectionDefinition;