import CardLink from "../../../components/html/card-link";
import HtmlHeader from "../../../components/html/html-header";
import { AbstractPage } from '../../abstractPage';

const DirectionsPage = () => {
  return (<AbstractPage>
    <HtmlHeader text="Направления в IT" type="h1" withMargin={true} />
    <CardLink
      title="Разработка"
      text="Информация о технологиях, используемых для написания серверной части приложений"
      url="/path/developers" />
    <CardLink
      title="Тестирование"
      text="Мануальное и автоматизированное тестирование"
      url="/path/testing" />
    <CardLink
      title="Devops"
      text=""
      url="/path/devops" />
  </AbstractPage>);
}

export default DirectionsPage;