import { SESSION_KEY } from './constants';

const defaultHeaders = {
  'Content-Type': 'application/json',
};

const jwtTokenHeader = (jwtToken) => {
  return {
    "Authorization": "Bearer " + jwtToken
  }
};

export const getRequest = async (url, withAuthentication) => {
  return httpRequest(url, withAuthentication);
}

export const postRequest = async (url, data, withAuthentication) => {
  return httpRequest(url, withAuthentication, "POST", JSON.stringify(data));
}

export const putRequest = async (url, data, withAuthentication) => {
  return httpRequest(url, withAuthentication, "PUT", JSON.stringify(data));
}

export const uploadFileRequest = async (url, data, withAuthentication) => {
  const customHeaders = {
    'Content-Type': 'multipart/form-data'
  }

  const form = new FormData();
  form.append("img", data);

  return httpRequest(url, withAuthentication, "POST", form, customHeaders);
}

const httpRequest = async (url, withAuthentication, method, body, customHeaders) => {

  let requestConfig = {}
  let requestHeaders = {};

  if (withAuthentication) {
    const token = localStorage.getItem(SESSION_KEY);
    requestHeaders = { ...requestHeaders, ...jwtTokenHeader(token) };
  }

  if (customHeaders) {
    requestHeaders = { ...requestHeaders, ...customHeaders };
  } else {
    requestHeaders = { ...requestHeaders, ...defaultHeaders };
  }

  requestConfig['method'] = method;
  requestConfig['headers'] = requestHeaders;
  if (body) {
    requestConfig['body'] = body;
  }

  const response = await fetch(url, requestConfig);
  const status = response.status;
  const data = await extractResponseData(response);
  // console.log("+\nREQUEST " + JSON.stringify(data) + " " + status + "\n+");

  return { data, status, ok: response.ok };
}

async function extractResponseData(response) {
  const contentType = response.headers.get("content-type");
  if (contentType === 'image/jpeg') {
    return response.text();
  } else {
    const text = await response.text()
    return text.length ? JSON.parse(text) : null;
  }

}