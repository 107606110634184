import React from 'react';
import { AbstractPage } from '../abstractPage';
import Login from '../../components/user/login';

const LoginPage = () => {

  return (
    <AbstractPage>
      <Login />
    </AbstractPage>
  );
}

export default LoginPage;