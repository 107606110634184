import './index.css';
import { ReactComponent as UpSign } from "../../assets/icons/up.svg";
import { ReactComponent as DownSign } from "../../assets/icons/down.svg";

const ChangeInPercent = (props) => {

  const { change, makeTextBold } = props;
  const absChangeInPercent = Math.abs(change / 100.0).toFixed(1);
  const boldTestClassName = makeTextBold ? 'bold-text' : ''
  const percent = change >= 0 ?
    (<span className={`change-in-percent positive ${boldTestClassName}`}><UpSign className="change-in-percent__icon" /> {absChangeInPercent}%</span>) :
    <span className={`change-in-percent negative ${boldTestClassName}`}><DownSign className="change-in-percent__icon" /> {absChangeInPercent}%</span>;

  return percent;
}

export default ChangeInPercent;