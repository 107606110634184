import React, { useContext } from 'react';
import { Menu, Popover } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import AuthenticationContext from '../../../common/auth-context/intex';
import './index.css';

const ProfileIcon = () => {
  const history = useHistory();
  const { logout, getUserName } = useContext(AuthenticationContext);

  const handleLogout = () => {
    logout();
    history.push('/');
  }

  const renderMenuItems = () => {
    return [{
      label: <Link to="/profile">Профиль</Link>,
      key: "1"
    },
    {
      label: <span onClick={handleLogout}>Выйти</span>,
      key: "2"
    }];
  }

  const userMenu = (<Menu items={renderMenuItems()} selectable={false} />
  );
  return (
    <div className='profile-icon__container'>
      <Popover placement="bottom" content={userMenu} trigger="hover" className='profile-icon__username' >
        <UserOutlined
          style={{ fontSize: '24px' }}
          className='profile-icon__icon' />
        <span style={{ color: "white" }}>{getUserName()}</span>
      </Popover>
    </div>
  );
};

export default ProfileIcon;