import React from 'react';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as QuestionSign } from "../../../assets/icons/question.svg";
import './index.css';

export const DevpulseTooltip = (props) => {

  const { id, text } = props;

  return (
    <span
    // className="topic-chart__current-value-info"
    >
      <QuestionSign data-tip data-for={"lang-chart-" + id} />
      <ReactTooltip id={"lang-chart-" + id} place="bottom" type="info" arrowColor='transparent' className="devpulse__tooltip">
        <span>{text}</span>
      </ReactTooltip>
    </span>

  );
}

export default DevpulseTooltip;