import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import './index.css';

export const MenuDesktop = ({ options }) => {
  const items = options
    .map(option => {
      const { url, label } = option;

      if (!url) {
        const { list } = option;
        return {
          label: (<span key={url} className="menu-desktop__link-container">
            <div className="menu-desktop__link" >
              {label}
            </div>
          </span>),
          children: list.map(item => {
            return {
              key: item.url,
              label: <Link key={item.url} className="menu-desktop__link-container" to={item.url}>
                <div className="menu-desktop__link" >
                  {item.label}
                </div>
              </Link>
            }
          })
        }
      } else {
        // const newMenuItem = url === '/resumes' ? <span style={{ color: "red", fontWeight: "bold" }}> new</span> : null;
        return {
          label: <Link key={url} className="menu-desktop__link-container" to={url}>
            <div className="menu-desktop__link" >
              {label}
            </div>
          </Link>
        }
      }
    })

  return <Menu className="menu-desktop" mode="horizontal" items={items} theme='dark' disabledOverflow={true} />;
}