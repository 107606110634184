import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form, Input, Button, Select, Alert } from 'antd';
import HtmlHeader from '../../html/html-header';
import { REGISTER_ACTIVATE_EMAIL_PAGE, LOGIN_PAGE, REGISTER_ENDPOINT } from '../../../common/constants';
import './index.scss';
import { postRequest } from '../../../common/client';

const { Option } = Select;

const Register = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [userType, setUserType] = useState(null);
  const history = useHistory();

  const onFinish = async (values) => {
    try {
      const { ok, data } = await postRequest(REGISTER_ENDPOINT, values, false);
      if (ok) {
        setErrorMessage(null);
        const { email } = data;
        history.push(REGISTER_ACTIVATE_EMAIL_PAGE, { email });
      } else {
        const { message } = data;
        setErrorMessage("Не удалось создать аккаунт: " + message);
      }
    } catch (error) {
      console.log(error);
      setErrorMessage("Не удалось создать аккаунт по неизвестной причине");
    }
  };

  function handleUserTypeChange(value) {
    setUserType(value);
  }

  const clearErrorMessage = () => {
    setErrorMessage(null);
  }

  const getNameLabel = () => {
    if (userType === "COMPANY") {
      return "Название компании";
    } else if (userType === "EDUCATIONAL_ORGANIZATION") {
      return "Название образовательной организации"
    } else if (userType === "MENTOR") {
      return "Имя ментора";
    }
  }

  return (
    <div className='devpulse__login-outer-сontainer'>
      <HtmlHeader text="Создать аккаунт" type='h2' />
      <div className="register__login-text"> или <Link to={LOGIN_PAGE}>войти в аккаунт</Link></div>
      <br></br>
      <div className='devpulse__login-inner-сontainer container'>
        {errorMessage ? <Alert message={errorMessage} type="error" closable onClose={clearErrorMessage} /> : null}
        <Form
          name="login"
          layout='vertical'
          labelCol={{
            span: 32,
          }}
          wrapperCol={{
            span: 32,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label="В качестве кого вы хотите зарегистрироваться?"
            name="userType"
            layout='vertical'
            rules={[
              {
                required: true,
                message: 'Пожалуйста, выберите одно из значений',
              },
            ]}
          >
            <Select onChange={handleUserTypeChange}>
              <Option value="EDUCATIONAL_ORGANIZATION">Образовательная организация</Option>
              <Option value="COMPANY">IT компания</Option>
              {/* <Option value="MENTOR">Ментор</Option> */}
            </Select>
          </Form.Item>

          {userType
            ? <Form.Item
              label={getNameLabel()}
              name="name"
              rules={[
                {
                  required: true,
                  type: 'string',
                  message: 'Пожалуйста, введите имя',
                },
              ]}
            >
              <Input />
            </Form.Item>
            : null}


          <Form.Item
            label="Электронная почта"
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Пожалуйста, введите электронную почту',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Пароль"
            name="password"
            rules={[
              {
                required: true,
                message: 'Пожалуйста, введите пароль',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="Пароль еще раз"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Пожалуйста, введите пароль',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Пароли не совпадают!'));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="devpulse__register__button">
              Создать аккаунт
            </Button>
          </Form.Item>
        </Form>
      </div >
    </div >
  );
}

export default Register;