import {
  ANGULAR_TOPIC_ID,
  JAVASCRIPT_TOPIC_ID,
  REACT_TOPIC_ID,
  SENIOR_ANGULAR_DEVELOPER_TOPIC_ID,
  JQUERY_TOPIC_ID,
  VUE_TOPIC_ID,
  HTML_TOPIC_ID,
  CSS_TOPIC_ID,
  SENIOR_REACT_DEVELOPER_TOPIC_ID,
  MIDDLE_REACT_DEVELOPER_TOPIC_ID,
  MIDDLE_ANGULAR_DEVELOPER_TOPIC_ID,
  JUNIOR_REACT_DEVELOPER_TOPIC_ID,
  JUNIOR_ANGULAR_DEVELOPER_TOPIC_ID,
  JUNIOR_VUE_DEVELOPER_TOPIC_ID,
  MIDDLE_VUE_DEVELOPER_TOPIC_ID,
  SENIOR_VUE_DEVELOPER_TOPIC_ID,
} from "../../../common/topic-constants";
import HtmlHeader from "../../../components/html/html-header";
import TopicChartContainer from "../../../components/charts/topic-chart-container";
import { AbstractPage } from '../../abstractPage';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const JavascriptPage = () => {
  return (<AbstractPage>
    <HtmlHeader text="Страница языка Javascript" type="h1" withMargin={true} />
    <TopicChartContainer topicId={JAVASCRIPT_TOPIC_ID} />
    <Tabs>
      <TabList>
        <Tab>Позиции</Tab>
        <Tab>Библиотеки</Tab>
        <Tab>Связанное</Tab>
      </TabList>
      <TabPanel>
        <HtmlHeader text="Позиции для frontend разработчиков:" type="h2" withMargin={true} />
        <TopicChartContainer topicId={JUNIOR_REACT_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={JUNIOR_ANGULAR_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={JUNIOR_VUE_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={MIDDLE_REACT_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={MIDDLE_ANGULAR_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={MIDDLE_VUE_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={SENIOR_REACT_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={SENIOR_ANGULAR_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={SENIOR_VUE_DEVELOPER_TOPIC_ID} />
      </TabPanel>
      <TabPanel>
        <HtmlHeader text="Популярные Javascript фреймворки и библиотеки:" type="h2" withMargin={true} />
        <TopicChartContainer topicId={REACT_TOPIC_ID} />
        <TopicChartContainer topicId={ANGULAR_TOPIC_ID} />
        <TopicChartContainer topicId={VUE_TOPIC_ID} />
      </TabPanel>
      <TabPanel>
        <HtmlHeader text="Связанное с Javascript:" type="h2" withMargin={true} />
        <TopicChartContainer topicId={HTML_TOPIC_ID} />
        <TopicChartContainer topicId={CSS_TOPIC_ID} />
        <TopicChartContainer topicId={JQUERY_TOPIC_ID} />
      </TabPanel>
    </Tabs>

    {/* <TopicChartContainer topicId={WORDPRESS_DEVELOPER_TOPIC_ID} /> */}
    {/* <HtmlHeader text="CMS:" type="h2" withMargin={true} />
    <TopicChartContainer topicId={DRUPAL_TOPIC_ID} />
    <TopicChartContainer topicId={WORDPRESS_TOPIC_ID} />
    <TopicChartContainer topicId={MAGENTO_TOPIC_ID} /> */}

  </AbstractPage>);
}

export default JavascriptPage;