import {
  ANGULAR_TOPIC_ID,
  JAVA_DEVELOPER_TOPIC_ID,
  JAVA_TOPIC_ID,
  JUNIOR_JAVA_DEVELOPER_TOPIC_ID,
  MIDDLE_JAVA_DEVELOPER_TOPIC_ID,
  SENIOR_JAVA_DEVELOPER_TOPIC_ID,
  SPRING_TOPIC_ID
} from "../../../common/topic-constants";
import HtmlHeader from "../../../components/html/html-header";
import TopicChartContainer from '../../../components/charts/topic-chart-container';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { AbstractPage } from '../../abstractPage';

const JavaPage = () => {
  return (<AbstractPage>
    <HtmlHeader text="Страница языка Java" type="h1" withMargin={true} />
    <TopicChartContainer topicId={JAVA_TOPIC_ID} />
    <Tabs>
      <TabList>
        <Tab>Позиции</Tab>
        <Tab>Библиотеки</Tab>
      </TabList>
      <TabPanel>
        <HtmlHeader text="Позиции для Java разработчиков:" type="h2" withMargin={true} />
        <TopicChartContainer topicId={JAVA_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={JUNIOR_JAVA_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={MIDDLE_JAVA_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={SENIOR_JAVA_DEVELOPER_TOPIC_ID} />
      </TabPanel>
      <TabPanel>
        <HtmlHeader text="Связанное с Java:" type="h2" withMargin={true} />
        <TopicChartContainer topicId={SPRING_TOPIC_ID} />
        <TopicChartContainer topicId={ANGULAR_TOPIC_ID} />
      </TabPanel>
    </Tabs>
  </AbstractPage>);
}

export default JavaPage;