import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#E7CFCD',
  '#1D2D44', '#FAEDCA'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }) => {
  const radiusForPercent = innerRadius + (outerRadius - innerRadius) * 0.5;
  const xForPercent = cx + radiusForPercent * Math.cos(-midAngle * RADIAN);
  const yForPercent = cy + radiusForPercent * Math.sin(-midAngle * RADIAN);

  const radiusForName = innerRadius + (outerRadius - innerRadius) * 1.1;
  const xForName = cx + radiusForName * Math.cos(-midAngle * RADIAN);
  const yForName = cy + radiusForName * Math.sin(-midAngle * RADIAN);

  return (
    <>
      <text x={xForName} y={yForName} fill="black" textAnchor={xForName > cx ? 'start' : 'end'} dominantBaseline="central">
        {name}
      </text>
      <text x={xForPercent} y={yForPercent} fill="white" textAnchor={xForPercent > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </>
  );
};

export const SimplePieChart = ({ data }) => {

  if (!data) {
    return <div>Loading...</div>
  }

  return (
    <ResponsiveContainer width="100%">
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={140}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
}