import MenuMobile from './menu-mobile';
import { MenuDesktop } from './menu-desktop';
import './index.css';
import { LANGUAGES_TOP_PAGE, LIST_WITH_COURSES_AND_INTERNSHIPS_PAGE } from '../../../common/relative-links';

const Menu = () => {
  const options = [
    { url: '/', label: 'Главная', mobileOnly: true },
    { url: LANGUAGES_TOP_PAGE, label: 'Топ языков' },
    { url: '/biggest-it-companies-by-employees-in-belarus', label: 'Топ компаний' },
    { url: '/resumes', label: 'Количество резюме' },
    {
      label: "Аналитика вакансий",
      list: [
        { url: '/demand-in-it/various-technologies', label: 'По технологиям', labelMobile: 'Аналитика по технологиям' },
        { url: '/path', label: "По направлениям в IT", labelMobile: 'Аналитика по направлениям в IT' },
        { url: '/positions', label: "По позициям и опыту", labelMobile: 'Аналитика по позициям и опыту' }
      ]
    },
    { url: LIST_WITH_COURSES_AND_INTERNSHIPS_PAGE, label: '🔥Курсы и стажировки' }
  ]

  return (
    <>
      <div className="menu__show-mobile-menu">
        <MenuMobile options={options} />
      </div>
      <div className="menu__show-desktop-menu">
        <MenuDesktop options={options} />
      </div>
    </>
  )
}

export default Menu;