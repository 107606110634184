import React from 'react';
import RegisterConfirmEmail from '../../components/user/register-confirm-email';
import { AbstractPage } from '../abstractPage';

const RegisterConfirmEmailPage = () => {

  return (
    <AbstractPage>
      <RegisterConfirmEmail />
    </AbstractPage>
  );
}

export default RegisterConfirmEmailPage;