import { useEffect } from 'react';
import {
  ANGULAR_DEVELOPER_TOPIC_ID,
  ANGULAR_TOPIC_ID,
  JAVASCRIPT_TOPIC_ID,
  REACT_DEVELOPER_TOPIC_ID,
  REACT_TOPIC_ID,
  TYPESCRIPT_TOPIC_ID,
  VUE_DEVELOPER_TOPIC_ID,
  VUE_TOPIC_ID
} from "../../../../../common/topic-constants";
import HtmlHeader from "../../../../../components/html/html-header";
import TopicChartContainer from "../../../../../components/charts/topic-chart-container";
import { AbstractPage } from '../../../../abstractPage';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { developersFrontendRelatedList } from '../../../../related-lists';
import RelatedPages from '../../../../../components/related-pages';

const FrontendPage = () => {

  useEffect(() => {
    document.title = "Востребованность языков и позиций из сферы Frontend | Devpulse.by — аналитика рынка IT вакансий в Беларуси";
  }, []);

  return (<AbstractPage>
    <HtmlHeader text="Востребованность языков и позиций из сферы Frontend" type="h1" withMargin={true} />
    <Tabs>
      <TabList>
        <Tab>Языки</Tab>
        <Tab>Фреймворки</Tab>
        <Tab>Позиции</Tab>
      </TabList>
      <TabPanel>
        <HtmlHeader text="Популярные Frontend языки:" type="h2" withMargin={true} />
        <TopicChartContainer topicId={JAVASCRIPT_TOPIC_ID} />
        <TopicChartContainer topicId={TYPESCRIPT_TOPIC_ID} />
      </TabPanel>
      <TabPanel>
        <HtmlHeader text="Популярные Javascript фреймворки:" type="h2" withMargin={true} />
        <TopicChartContainer topicId={REACT_TOPIC_ID} />
        <TopicChartContainer topicId={ANGULAR_TOPIC_ID} />
        <TopicChartContainer topicId={VUE_TOPIC_ID} />
      </TabPanel>
      <TabPanel>
        <HtmlHeader text="Позиции Frontend разработчиков" type="h2" withMargin={true} />
        <TopicChartContainer topicId={REACT_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={ANGULAR_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={VUE_DEVELOPER_TOPIC_ID} />
      </TabPanel>
    </Tabs>
    <RelatedPages list={developersFrontendRelatedList} />
  </AbstractPage>);
}

export default FrontendPage;