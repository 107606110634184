import React, { useState, useEffect } from 'react';
import { getRequest } from '../../common/client';
import { PUBLICATIONS_ENDPOINT } from '../../common/constants';
import { formatDate, fromStringToDate } from '../../common/utils';
import './index.css';

const Publications = () => {

  const [publications, setPublications] = useState(null);

  useEffect(() => {

    (async () => {
      const { ok, data } = await getRequest(PUBLICATIONS_ENDPOINT, false);

      if (ok) {
        setPublications(data);
      }
    })();
  }, []);

  if (!publications) {
    return (
      <div className="container">
        Загрузка...
      </div>
    )
  }

  // publications.sort((pub) => pub.registrationOpen ? -1 : 1)

  const lastUpdated = [...publications].sort((a, b) => fromStringToDate(b.updated) - fromStringToDate(a.updated))[0].updated

  return (
    <div>
      {/* <p>🔥 означает, что регистрация на курсы или стажировку сейчас открыта</p> */}
      <div className='publications__info'>
          <div>Количество курсов/стажировок: {publications.length}</div>
          <div>Обновлено: {formatDate(lastUpdated)}</div>
        </div>
      <div className="publications container" >
      
        {
          publications && publications.length && publications.length !== 0 ? publications.map(publication => {
            const { id, title, shortDescription, link, registrationOpen } = publication;
            return (<>
              <div key={id} className="publication">
                <div className='publication__title'>
                  {registrationOpen && <span>🔥 </span>}
                  {title}
                </div>
                <div>{shortDescription}</div>
                <div className='publication__link'><a href={link + "?source=devpulse.by"} >{link}</a></div>
              </div>
              {/* <hr className='publication__hr' /> */}
            </>)
          }) : (<div className="publication">
            Ничего не найдено
          </div>)
        }
      </div>
    </div>
  );
}

export default Publications;