import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { COURSES_PUB_ENDPOINT } from '../../../common/constants';
import { getRequest } from '../../../common/client';
import { formatDate } from '../../../common/utils';
import HtmlHeader from '../../html/html-header';
import AuthenticationContext from '../../../common/auth-context/intex';
import './index.css';
import { LIST_WITH_COURSES_AND_INTERNSHIPS_PAGE } from '../../../common/relative-links';

export const Course = (props) => {
  const [course, setCourse] = useState();
  const { getUserId } = useContext(AuthenticationContext);
  const currentUserId = getUserId();
  let { courseId } = props;
  useEffect(() => {
    (async () => {
      try {
        const { ok, data } = await getRequest(COURSES_PUB_ENDPOINT + "/" + courseId, false);

        if (ok) {
          setCourse({ ...{ visible: true }, ...data });
        } else {
          setCourse({ visible: false });
        }
      } catch (e) {
        setCourse({ visible: false });
      }

    })();

  }, [courseId]);


  if (!course) {
    return (
      <div className="course container-without-padding">
        Загрузка...
      </div>
    )
  }

  const renderContact = (id, value, contactType) => {
    switch (contactType) {
      case "EMAIL":
        return (<div key={id} className="course__contact-item course__contact-email">
          <div>{value}</div>
        </div>);
      case "PHONE":
        return (<div key={id} className="course__contact-item course__contact-phone">
          <a href={"tel:" + value}>{value}</a>
        </div>);
      case "LINK":
        return (<div key={id} className="course__contact-item course__contact-link">
          <a href={value} target="_blank" rel="noreferrer">{value}</a>
        </div>);
      case "OTHER":
        return (<div key={id} className="course__contact-item course__contact-other">
          <div>{value}</div>
        </div>);
      default:
        return (<div key={id} className="course__contact-item course__contact-email">
          <div>{value}</div>
        </div>);
    }
  };

  const renderCourse = () => {
    const { name, descriptionFull, author, price, updated, contacts } = course;
    const { name: authorName, id: authorId } = author;

    const linkContact = contacts.find(c => c.contactType === "LINK");
    contacts.sort((contact1, contact2) => contact1.id - contact2.id);
    return (
      <div className="course container-without-padding">
        <div className="course__title">
          <HtmlHeader text={name} type="h2" />
          {authorId === currentUserId ? <div className='course__only_for_owner_text'>Курс виден только вам</div> : null}
        </div>
        <div className="course__header">
          <div className="course__author">
            {authorName}
          </div>
          <div>{price === 0 ? "Бесплатно" : `Cтоимость: ${price} руб.`}</div>
        </div>

        <div className="course__description" dangerouslySetInnerHTML={{ __html: (descriptionFull.replaceAll('\n', "</br>")) }} />
        {linkContact && (
          <div className="course__controls">
            <a className="course__visit-site-button" href={linkContact.value}>Перейти на сайт курсов</a>
          </div>
        )}
        <div className="course__header-margin">
          <HtmlHeader text="Контакты" type="h4" />
        </div>
        <div className="course__contacts">
          {contacts && contacts.map(contact => {
            const { id, value, contactType } = contact;
            return renderContact(id, value, contactType);
          })}
        </div>
        <div className="course__info">
          Обновлено: {formatDate(updated)}
        </div>
      </div>
    )
  }

  const { visible, author } = course;
  const { id: authorId } = author;
  const showCourse = visible || currentUserId === authorId;

  return (
    <div>
      <div className='course__breadcrumb-container'>
        <NavLink className="course__breadcrumb"
          to={LIST_WITH_COURSES_AND_INTERNSHIPS_PAGE}>&lt; вернуться к поиску курсов</NavLink>
      </div>
      {showCourse
        ? renderCourse()
        : <div className="container">
          Публикация была скрыта или удалена автором
        </div>}

    </div>
  );
}