import React, { useState } from 'react';
import jwtDecode from "jwt-decode";
import { SESSION_KEY } from '../constants';

const defaultContext = {
  setToken: () => { },
  isAuthenticated: () => false,
  getUserRole: () => null,
  tryLoadSessionFromStorage: () => { },
  logout: () => { },
  hasCompanyFeature: () => { },
  hasCourseProviderFeature: () => { },
  hasJobsFeature: () => { },
  hasMentorFeature: () => { }
}

export const extractDateFromToken = (token) => {
  const decoded = jwtDecode(token);
  if (decoded && decoded.exp) {
    const { exp } = decoded;
    return new Date((exp + 0) * 1000);
  } else {
    return new Date(1000);
  }
}

export const isTokenExpired = (token, expDate) => {
  if (expDate) {
    return expDate > new Date();
  } else {
    return extractDateFromToken(token) > new Date();
  }
}

const AuthenticationContext = React.createContext(defaultContext);
AuthenticationContext.displayName = 'AuthenticationContext';

const AuthenticationProvider = ({ children }) => {
  /* eslint-disable no-unused-vars */
  const [dummyVariableForNotifingComponents, setDummyVariableForNotifingComponents] = useState(null);

  const context = {
    setToken: (token) => {
      if (token) {
        localStorage.setItem(SESSION_KEY, token);
        setDummyVariableForNotifingComponents("user logged in");
      } else {
        throw new Error("token not provided");
      }
    },
    isAuthenticated: () => {
      const token = localStorage.getItem(SESSION_KEY);
      if (token) {
        return isTokenExpired(token);
      }

      return false;
    },
    logout: () => {
      localStorage.removeItem(SESSION_KEY);
      setDummyVariableForNotifingComponents("logout");
    },
    getUserId: () => {
      const token = localStorage.getItem(SESSION_KEY);
      const decoded = jwtDecode(token);
      return decoded.userId;
    },
    getUserName: () => {
      const token = localStorage.getItem(SESSION_KEY);
      const decoded = jwtDecode(token);
      return decoded.name;
    },
    getUserRole: () => {

    },
    hasCompanyFeature: () => {
      const token = localStorage.getItem(SESSION_KEY);
      const decoded = jwtDecode(token);
      const { features } = decoded;
      return features.find(feature => feature === "COMPANY");
    },
    hasCourseProviderFeature: () => {
      const token = localStorage.getItem(SESSION_KEY);
      const decoded = jwtDecode(token);
      const { features } = decoded;
      return features.find(feature => feature === "COURSE_PROVIDER");
    },
    hasJobsFeature: () => {
      return false;
    },
    hasMentorFeature: () => {
      return false;
    }
  };

  return (
    <AuthenticationContext.Provider value={context}>
      {children}
    </AuthenticationContext.Provider>
  );
}

export { AuthenticationProvider };
export default AuthenticationContext;