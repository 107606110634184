import { Link } from 'react-router-dom';
import TopicChart from '../topic-chart';
import './index.scss';

const TopicChartContainer = (props) => {
  const { topicId, withLink = false, type, period } = props;

  const renderLink = () => {
    return (<div className="topic-chart-container__link">
      <Link to={"/languages/" + topicId}> Перейти на страницу языка &gt;</Link>
    </div>)
  }

  return (
    <div className="topic-chart-container">
      <TopicChart topicId={topicId} type={type} period={period} />
      {withLink && (renderLink())}
    </div>
  );
}

export default TopicChartContainer;