import BelarusItStatusNavbarDesktop from '../../bel-it-jobs-status-navbar-desktop';
import {
  Link
} from "react-router-dom";
// import { ReactComponent as Logo } from '../../../assets/icons/logo.svg';
import Menu from '../menu';
import ProfileIcon from '../../user/profile-icon';
import { useContext } from 'react';
import AuthenticationContext from '../../../common/auth-context/intex';
import './index.css';

const Navbar = () => {

  const { isAuthenticated } = useContext(AuthenticationContext);

  return (
    <div className='navbar' >
      <div className="navbar-container">
        <div className="navbar-container__logo-container">
          <Link to="/">
            <div className="navbar-container__logo">
              {/* <Logo className="navbar-container__logo-icon" /> */}
              <div className="navbar-container__logo-text">
                Devpulse
              </div>
            </div>
          </Link>
        </div>
        <div className="navbar-container__menu">
          <Menu />
          <BelarusItStatusNavbarDesktop />
        </div>
        {isAuthenticated() ? <ProfileIcon /> : null}
      </div>
    </div>);
}

export default Navbar;