import React from 'react';
import DevpulseTooltip from '../../html/tooltip';
// import ChangeInPercent from '../../change-in-percent';
import { ConfigurableTable } from '../configurable-table';
import './index.css';

export const TopItCompaniesByEmployeesTable = (props) => {

  // const { rowLinkBase, rowLinkParamName, showPlace, showWeekChange,
  //   showMonthChange, capitalizedNames = false } = props;

  const title = (
    <div className="companies-emmployees-table__title">
      <div>Количество сотрудников в IT компаниях РБ</div>
      <span className="companies-emmployees-table__tooltip">
        <DevpulseTooltip
          id={1}
          text="Данные взяты из профилей компаний в LinkedIn" />
      </span>
    </div>
  )

  const comparatorForMonthRbChange = (row1, row2) => {
    const extractValue = (children) => {
      if (Array.isArray(children)) {
        return children[1];
      } else {
        return children;
      }
    }

    const children1 = row1.values.monthRb.props.children;
    const children2 = row2.values.monthRb.props.children;
    let val1 = extractValue(children1);
    let val2 = extractValue(children2);

    return val1 > val2 ? 1 : -1;
  }

  const columns = React.useMemo(
    () => {
      let cols = [
        {
          Header: '#',
          accessor: 'place'
        },
        {
          Header: 'Название компании',
          accessor: 'name'
        },
        {
          Header: 'Всего работников',
          accessor: 'world'
        },
        {
          Header: 'В РБ',
          accessor: 'rb'
        },
        {
          Header: 'За месяц в РБ',
          accessor: 'monthRb',
          sortType: comparatorForMonthRbChange
        }
      ];

      return cols;
    },
    []
  )

  const renderValue = (value) => {
    return value >= 0 ?
      <span className="positive">+{value}</span> :
      <span className="negative">{value}</span>
  }

  const dataMapper = ({ item, place }) => {
    const { topicName, lastWorld, lastRb, previousRb } = item;

    let result = {
      place,
      name: topicName,
      world: lastWorld,
      rb: lastRb,
      monthRb: renderValue(lastRb - previousRb)
    }

    return result;
  };

  return (<ConfigurableTable
    {...props}
    fullTablePageUrl="/biggest-it-companies-by-employees-in-belarus"
    columns={columns}
    dataMapper={dataMapper}
    title={title}
    removeFirstColumnInMobileVersion={true}
  />);
}