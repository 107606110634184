import { useEffect } from 'react';
import {
  CSHARP_TOPIC_ID,
  GO_TOPIC_ID,
  JAVASCRIPT_TOPIC_ID,
  JAVA_DEVELOPER_TOPIC_ID,
  JAVA_TOPIC_ID,
  NET_DEVELOPER_TOPIC_ID,
  PHP_DEVELOPER_TOPIC_ID,
  PHP_TOPIC_ID,
  PYTHON_DEVELOPER_TOPIC_ID,
  PYTHON_TOPIC_ID,
  // RUBY_DEVELOPER_TOPIC_ID,
  RUBY_TOPIC_ID,
  SCALA_DEVELOPER_TOPIC_ID,
  SCALA_TOPIC_ID,
  TYPESCRIPT_TOPIC_ID
} from "../../../../../common/topic-constants";
import HtmlHeader from "../../../../../components/html/html-header";
import TopicChartContainer from '../../../../../components/charts/topic-chart-container';
import { AbstractPage } from '../../../../abstractPage';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import RelatedPages from '../../../../../components/related-pages';
import { developersBackendRelatedList } from '../../../../related-lists';

const BackendPage = () => {

  useEffect(() => {
    document.title = "Востребованность Backend языков и позиций | Devpulse.by — аналитика рынка IT вакансий в Беларуси";
  }, []);


  return (<AbstractPage>
    <HtmlHeader text="Востребованность языков и позиций в Backend направлении" type="h1" withMargin={true} />
    <Tabs>
      <TabList>
        <Tab>Языки</Tab>
        <Tab>Позиции</Tab>
      </TabList>
      <TabPanel>
        <HtmlHeader text="Количество вакансии для Backend языков:" type="h2" withMargin={true} />
        <TopicChartContainer topicId={JAVA_TOPIC_ID} />
        <TopicChartContainer topicId={PYTHON_TOPIC_ID} />
        <TopicChartContainer topicId={CSHARP_TOPIC_ID} />
        <TopicChartContainer topicId={GO_TOPIC_ID} />
        <TopicChartContainer topicId={JAVASCRIPT_TOPIC_ID} />
        <TopicChartContainer topicId={TYPESCRIPT_TOPIC_ID} />
        <TopicChartContainer topicId={RUBY_TOPIC_ID} />
        <TopicChartContainer topicId={PHP_TOPIC_ID} />
        <TopicChartContainer topicId={SCALA_TOPIC_ID} />
      </TabPanel>
      <TabPanel>
        <HtmlHeader text="Количество вакансии для Backend разработчиков" type="h2" withMargin={true} />
        <TopicChartContainer topicId={JAVA_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={PYTHON_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={NET_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={PHP_DEVELOPER_TOPIC_ID} />
        {/* <TopicChartContainer topicId={RUBY_DEVELOPER_TOPIC_ID} /> */}
        <TopicChartContainer topicId={SCALA_DEVELOPER_TOPIC_ID} />
      </TabPanel>
    </Tabs>
    <RelatedPages list={developersBackendRelatedList} />
  </AbstractPage>);
}

export default BackendPage;