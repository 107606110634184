import {
  JENKINS_TOPIC_ID,
  JUNIOR_TEST_AUTOMATION_ENGINEER_TOPIC_ID,
  MIDDLE_TEST_AUTOMATION_ENGINEER_TOPIC_ID,
  SELENIUM_TOPIC_ID,
  SELENIUM_WEBDRIVER_TOPIC_ID,
  SENIOR_QA_AUTOMATION_ENGINEER_TOPIC_ID
} from '../../../../../common/topic-constants';
import HtmlHeader from "../../../../../components/html/html-header";
import TopicChartContainer from "../../../../../components/charts/topic-chart-container";
import { AbstractPage } from '../../../../abstractPage';
import RelatedPages from '../../../../../components/related-pages';
import { automataedTestingRelatedList } from '../../../../related-lists';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

const TestAutomationEngineerPage = () => {
  return (<AbstractPage>
    <HtmlHeader text="Автоматизированное тестирование. Востребованность позиций и технологий" type="h1" withMargin={true} />
    <Tabs>
      <TabList>
        <Tab>Позиции</Tab>
        <Tab>Технологии</Tab>
      </TabList>
      <TabPanel>
        <HtmlHeader text="Востребованность по опыту" type="h2" withMargin={true} />
        <TopicChartContainer topicId={JUNIOR_TEST_AUTOMATION_ENGINEER_TOPIC_ID} />
        <TopicChartContainer topicId={MIDDLE_TEST_AUTOMATION_ENGINEER_TOPIC_ID} />
        <TopicChartContainer topicId={SENIOR_QA_AUTOMATION_ENGINEER_TOPIC_ID} />
      </TabPanel>
      <TabPanel>
        <HtmlHeader text="Востребованность технологий:" type="h2" withMargin={true} />
        <TopicChartContainer topicId={SELENIUM_TOPIC_ID} />
        <TopicChartContainer topicId={SELENIUM_WEBDRIVER_TOPIC_ID} />
        <TopicChartContainer topicId={JENKINS_TOPIC_ID} />
      </TabPanel>
    </Tabs>



    <RelatedPages list={automataedTestingRelatedList} />
  </AbstractPage>);
}

export default TestAutomationEngineerPage;