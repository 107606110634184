import { useEffect } from 'react';
import {
  DEVELOPERS_TABLE_FULL_DATA_ENDPOINT,
  FRONTEND_FRAMEWORKS_TABLE_DATA_ENDPOINT
} from '../../../common/constants';
import HtmlHeader from "../../../components/html/html-header";
import { AbstractPage } from '../../abstractPage';
import BarChart from '../../../components/bar-chart';
import TableOfContents from '../../../components/table-of-contents';
import { JobsTable } from '../../../components/tables/jobs-table';
import { topsRelatedList } from '../../related-lists';
import RelatedPages from '../../../components/related-pages';
import MultipleTopicsLineChartContainer from '../../../components/charts/multiple-topics-line-chart-container';
import {
  BACKEND_FRAMEWORKS_TOPICS_IDS,
  CLOUD_PROVIDERS_TOPICS_IDS,
  DATABASES_TOPICS_IDS
} from '../../../common/topic-constants';

const DemandVariousTechPage = () => {

  useEffect(() => {
    document.title = "Данные по востребованности различных технологий из сферы IT | Devpulse.by — аналитика рынка IT вакансий в Беларуси";
  }, []);

  return (<AbstractPage>
    <HtmlHeader text="Данные по востребованности различных технологий из сферы IT" type="h1" withMargin={true} />

    <TableOfContents anchors={[
      { link: "#developers", text: "Востребованность разработчиков по языкам" },
      { link: "#backend-frameworks", text: "Бекенд фреймворки" },
      { link: "#backend-frameworks-dynamic", text: "Динамика востребованности бекенд фреймворков" },
      { link: "#frontend-frameworks", text: "Фронтенд фреймворки" },
      { link: "#databases", text: "Базы данных" },
      { link: "#databases-dynamic", text: "Динамика востребованности баз данных" },
      { link: "#cloud-providers-dynamic", text: "Облачные провайдеры" }
    ]} />

    <JobsTable
      title="Востребованность разработчиков по языкам"
      showFullList={true}
      showTitle={true}
      apiUrl={DEVELOPERS_TABLE_FULL_DATA_ENDPOINT}
      rowsClickable={false}
      rowLinkBase="/topic/"
      rowLinkParamName="topicId"
      showPlace={true}
      showWeekChange={false}
      showMonthChange={true}
      capitalizedNames={true}
      anchorId="developers"
    />

    <JobsTable
      title="Фронтенд фреймворки"
      showTitle={true}
      showFullTable={true}
      apiUrl={FRONTEND_FRAMEWORKS_TABLE_DATA_ENDPOINT}
      rowsClickable={false}
      rowLinkBase="/languages/"
      rowLinkParamName="topicId"
      showPlace={true}
      showWeekChange={false}
      showMonthChange={true}
      anchorId="frontend-frameworks"
    />

    <BarChart
      title="Востребованность бекенд фреймворков"
      topics={BACKEND_FRAMEWORKS_TOPICS_IDS}
      numberOfTopics={7}
      anchorId="backend-frameworks" />

    <MultipleTopicsLineChartContainer
      title="Динамика востребованности бекенд фреймворков"
      topics={BACKEND_FRAMEWORKS_TOPICS_IDS}
      anchorId="backend-frameworks-dynamic" />

    <BarChart
      title="Востребованность баз данных"
      topics={DATABASES_TOPICS_IDS}
      numberOfTopics={8}
      anchorId="databases" />

    <MultipleTopicsLineChartContainer
      title="Динамика востребованности баз данных"
      topics={DATABASES_TOPICS_IDS}
      anchorId="databases-dynamic" />

    <MultipleTopicsLineChartContainer
      title="Динамика востребованности облачных провайдеров"
      topics={CLOUD_PROVIDERS_TOPICS_IDS}
      anchorId="cloud-providers-dynamic" />

    <RelatedPages list={topsRelatedList} />
  </AbstractPage>);
}

export default DemandVariousTechPage;