import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {
  CSHARP_TOPIC_ID,
  SENIOR_CSHAPR_DEVELOPER_TOPIC_ID,
  NET_TOPIC_ID,
  NET_CORE_TOPIC_ID,
  NET_DEVELOPER_TOPIC_ID,
  SQLSERVER_TOPIC_ID,
  XAMARIN_TOPIC_ID,
  AZURE_TOPIC_ID,
  JUNIOR_NET_DEVELOPER_TOPIC_ID,
  SENIOR_NET_DEVELOPER_TOPIC_ID
} from "../../../common/topic-constants";
import TopicChartContainer from "../../../components/charts/topic-chart-container";
import HtmlHeader from "../../../components/html/html-header";
import { AbstractPage } from '../../abstractPage';

const CsharpPage = () => {
  return (<AbstractPage>
    <HtmlHeader text="Страница языка C#" type="h1" withMargin={true} />
    <TopicChartContainer topicId={CSHARP_TOPIC_ID} />

    <Tabs>
      <TabList>
        <Tab>Позиции</Tab>
        <Tab>Библиотеки</Tab>
      </TabList>
      <TabPanel>
        <HtmlHeader text="Позиции для C# разработчиков:" type="h2" withMargin={true} />
        <TopicChartContainer topicId={NET_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={SENIOR_CSHAPR_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={JUNIOR_NET_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={SENIOR_NET_DEVELOPER_TOPIC_ID} />
      </TabPanel>
      <TabPanel>
        <HtmlHeader text="Связанное с C#:" type="h2" withMargin={true} />
        <TopicChartContainer topicId={NET_TOPIC_ID} />
        <TopicChartContainer topicId={NET_CORE_TOPIC_ID} />
        <TopicChartContainer topicId={XAMARIN_TOPIC_ID} />
        <TopicChartContainer topicId={SQLSERVER_TOPIC_ID} />
        <TopicChartContainer topicId={AZURE_TOPIC_ID} />
      </TabPanel>
    </Tabs>



  </AbstractPage>);
}

export default CsharpPage;