import HtmlHeader from '../html/html-header';
import "./index.css";

const TableOfContents = (props) => {

  const { anchors } = props;

  return (<div className="table-of-contents container">
    <div className="table-of-contents__header">
      <HtmlHeader text="Содержание:" type="h3" />
    </div>
    {anchors.map(anchor => <a key={anchor.link} className="table-of-contents__link" href={anchor.link}>• {anchor.text}</a>)}
  </div >);
}

export default TableOfContents;