import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { AbstractPage } from '../../abstractPage';
import {
  MIDDLE_ANDROID_DEVELOPER_TOPIC_ID,
  MIDDLE_ANGULAR_DEVELOPER_TOPIC_ID,
  MIDDLE_BUSINESS_ANALYST_TOPIC_ID,
  MIDDLE_CPLUSPLUS_DEVELOPER_TOPIC_ID,
  MIDDLE_DEVOPS_TOPIC_ID,
  MIDDLE_IOS_DEVELOPER_TOPIC_ID, MIDDLE_JAVA_DEVELOPER_TOPIC_ID,
  MIDDLE_NET_DEVELOPER_TOPIC_ID, MIDDLE_PHP_DEVELOPER_TOPIC_ID,
  MIDDLE_PROJECT_MANAGER_TOPIC_ID, MIDDLE_QA_ENGINEER_TOPIC_ID,
  MIDDLE_REACT_DEVELOPER_TOPIC_ID, MIDDLE_REACT_NATIVE_DEVELOPER_TOPIC_ID,
  // MIDDLE_RECRUITER_TOPIC_ID, 
  MIDDLE_RUBY_DEVELOPER_TOPIC_ID,
  MIDDLE_SALESFORCE_DEVELOPER_TOPIC_ID, MIDDLE_SCALA_DEVELOPER_TOPIC_ID,
  MIDDLE_TEST_AUTOMATION_ENGINEER_TOPIC_ID, MIDDLE_UNITY_DEVELOPER_TOPIC_ID,
  MIDDLE_VUE_DEVELOPER_TOPIC_ID
} from '../../../common/topic-constants';
import TopicChartContainer from '../../../components/charts/topic-chart-container';
import HtmlHeader from '../../../components/html/html-header';

export const MiddlePositionsPage = () => {
  return (<AbstractPage>
    <HtmlHeader text="Востребованность Middle специалистов" type="h1" withMargin={true} />

    <Tabs>
      <TabList>
        <Tab>Backend</Tab>
        <Tab>Frontend</Tab>
        <Tab>Mobile</Tab>
        <Tab>GameDev</Tab>
        <Tab>Testing</Tab>
        <Tab>Management</Tab>
        <Tab>Recruting</Tab>
      </TabList>
      <TabPanel>
        <TopicChartContainer topicId={MIDDLE_JAVA_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={MIDDLE_NET_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={MIDDLE_CPLUSPLUS_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={MIDDLE_PHP_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={MIDDLE_RUBY_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={MIDDLE_SALESFORCE_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={MIDDLE_DEVOPS_TOPIC_ID} />
        <TopicChartContainer topicId={MIDDLE_SCALA_DEVELOPER_TOPIC_ID} />

      </TabPanel>
      <TabPanel>
        <TopicChartContainer topicId={MIDDLE_REACT_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={MIDDLE_ANGULAR_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={MIDDLE_VUE_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={MIDDLE_UNITY_DEVELOPER_TOPIC_ID} />
      </TabPanel>
      <TabPanel>
        <TopicChartContainer topicId={MIDDLE_ANDROID_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={MIDDLE_IOS_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={MIDDLE_REACT_NATIVE_DEVELOPER_TOPIC_ID} />
      </TabPanel>
      <TabPanel>
        <TopicChartContainer topicId={MIDDLE_UNITY_DEVELOPER_TOPIC_ID} />
      </TabPanel>
      <TabPanel>
        <TopicChartContainer topicId={MIDDLE_TEST_AUTOMATION_ENGINEER_TOPIC_ID} />
        <TopicChartContainer topicId={MIDDLE_QA_ENGINEER_TOPIC_ID} />
      </TabPanel>
      <TabPanel>
        <TopicChartContainer topicId={MIDDLE_PROJECT_MANAGER_TOPIC_ID} />
        <TopicChartContainer topicId={MIDDLE_BUSINESS_ANALYST_TOPIC_ID} />
      </TabPanel>
      <TabPanel>
        {/* <TopicChartContainer topicId={MIDDLE_RECRUITER_TOPIC_ID} /> */}
        пока пусто...
      </TabPanel>
    </Tabs>

  </AbstractPage >);
}