import React, { useEffect, useState } from 'react';
import { RECORDS_FOR_TOPICS_ENDPOINT, TOPICS_BY_IDS_ENDPOINT } from '../../../common/constants';
import { fromStringToDate } from '../../../common/utils';
import { getRequest } from '../../../common/client';
import MultipleTopicsLineChartView from '../multiple-topics-line-chart-view';

const MultipleTopicsLineChartContainer = (props) => {
  const { title, topics, anchorId } = props;
  const topicsAsString = "?topics=" + topics.join(",");
  const [data, setData] = useState(null);
  const [topicsResources, setTopicsResources] = useState(null);

  useEffect(() => {
    (async () => {
      const { ok, data: responseData } = await getRequest(RECORDS_FOR_TOPICS_ENDPOINT + topicsAsString, false);

      if (ok) {
        const list = [];

        for (const [key, value] of Object.entries(responseData)) {
          const listItem = {
            xval: key,
          };
          for (let i = 0; i < value.length; i++) {
            const keyAndValue = value[i];
            listItem[keyAndValue.key] = keyAndValue.value;
          }
          list.push(listItem);
        }

        list.sort((a, b) => fromStringToDate(a.xval).diff(fromStringToDate(b.xval)));
        setData(list);
      }
    })();

  }, [topics]);


  useEffect(() => {
    (async () => {
      const { ok, data: responseData } = await getRequest(TOPICS_BY_IDS_ENDPOINT + topicsAsString, false);
      if (ok) {
        setTopicsResources(responseData);
      }
    })();
  }, [topicsAsString]);


  return (
    <MultipleTopicsLineChartView
      title={title}
      anchorId={anchorId}
      data={data}
      topicsResources={topicsResources}
    />
  );
}

export default MultipleTopicsLineChartContainer;