import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getRequest } from '../../../common/client';
import { BLOG_POSTS_ENDPOINT } from '../../../common/constants';
import { formatDate } from '../../../common/utils';
import "../blog-post/index.css";

const AllBlogPosts = () => {

  const [blogPosts, setBlogPosts] = useState();

  useEffect(() => {
    (async () => {
      try {
        const { ok, data } = await getRequest(BLOG_POSTS_ENDPOINT, false);

        if (ok) {
          setBlogPosts(data);
        }
      } catch (error) {

      }

    })();

  }, [])

  if (!blogPosts) {
    return <div className='container'>Загрузка...</div>
  }

  const emptyBlogMessage = () => (
    <div className='container'>Публикаций еще нет...</div>
  );

  const renderPosts = () => {
    return blogPosts.map(post => {
      const { readableId, title, content, created } = post;
      return (
        <div className='container'>
          <h2><Link to={"/blog/post/" + readableId}>{title}</Link></h2>
          <div className='blog-post__created'>Опубликовано: {formatDate(created)}</div>
          <div>
            <div dangerouslySetInnerHTML={{ __html: content.slice(0, 150) + "..." }} />
          </div>
        </div>);
    })
  }

  return (<div>
    {blogPosts.length === 0 ? emptyBlogMessage() : renderPosts()}
  </div>);
}

export default AllBlogPosts;