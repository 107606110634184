import {
  ANDROID_DEVELOPER_TOPIC_ID, ANGULAR_TOPIC_ID, AWS_TOPIC_ID, AZURE_TOPIC_ID, CASSANDRA_TOPIC_ID, CPLUSPLUS_TOPIC_ID, CSHARP_TOPIC_ID, DELPHI_TOPIC_ID,
  DJANGO_TOPIC_ID, DYNAMODB_TOPIC_ID, FLASK_TOPIC_ID, FLUTTER_DEVELOPER_TOPIC_ID,
  GOOGLE_CLOUD_PLATFORM_TOPIC_ID,
  CPLUSPLUS_DEVELOPER_TOPIC_ID, RUBY_DEVELOPER_TOPIC_ID, DELPHI_DEVELOPER_TOPIC_ID,
  GO_DEVELOPER_TOPIC_ID, ONEC_DEVELOPER_TOPIC_ID,
  GO_TOPIC_ID, IOS_DEVELOPER_TOPIC_ID, JAVASCRIPT_TOPIC_ID, JAVA_DEVELOPER_TOPIC_ID, JAVA_TOPIC_ID, KOTLIN_TOPIC_ID, LARAVEL_TOPIC_ID,
  MONGODB_TOPIC_ID, MYSQL_TOPIC_ID, NET_DEVELOPER_TOPIC_ID, NET_TOPIC_ID, OBJECTIVEC_TOPIC_ID, ORACLE_TOPIC_ID, PHP_DEVELOPER_TOPIC_ID,
  PHP_TOPIC_ID, POSTGRESQL_TOPIC_ID, PYTHON_DEVELOPER_TOPIC_ID, PYTHON_TOPIC_ID, REACT_DEVELOPER_TOPIC_ID,
  REACT_NATIVE_DEVELOPER_TOPIC_ID, REACT_TOPIC_ID, REDIS_TOPIC_ID, ROR_TOPIC_ID, RUBY_TOPIC_ID, RUST_DEVELOPER_TOPIC_ID,
  SALESFORCE_DEVELOPER_TOPIC_ID, SCALA_DEVELOPER_TOPIC_ID, SCALA_TOPIC_ID, SPRING_TOPIC_ID, SQLITE_TOPIC_ID, SQLSERVER_TOPIC_ID, SWIFT_TOPIC_ID,
  SYMFONY_TOPIC_ID, TYPESCRIPT_TOPIC_ID, UNITY_DEVELOPER_TOPIC_ID,
  VUE_DEVELOPER_TOPIC_ID, VUE_TOPIC_ID, WORDPRESS_DEVELOPER_TOPIC_ID, JUNIOR_JAVA_DEVELOPER_TOPIC_ID, JUNIOR_NET_DEVELOPER_TOPIC_ID, JUNIOR_NODE_DEVELOPER_TOPIC_ID, JUNIOR_PHP_DEVELOPER_TOPIC_ID, JUNIOR_CPLUSPLUS_DEVELOPER_TOPIC_ID, JUNIOR_GO_DEVELOPER_TOPIC_ID, JUNIOR_ANDROID_DEVELOPER_TOPIC_ID, JUNIOR_RUBY_DEVELOPER_TOPIC_ID, JUNIOR_IOS_DEVELOPER_TOPIC_ID, JUNIOR_SCALA_DEVELOPER_TOPIC_ID, JUNIOR_REACT_DEVELOPER_TOPIC_ID, JUNIOR_ANGULAR_DEVELOPER_TOPIC_ID, JUNIOR_VUE_DEVELOPER_TOPIC_ID, JUNIOR_UNITY_DEVELOPER_TOPIC_ID, JUNIOR_PROJECT_MANAGER_TOPIC_ID, JUNIOR_SALESFORCE_DEVELOPER_TOPIC_ID, JUNIOR_TEST_AUTOMATION_ENGINEER_TOPIC_ID, JUNIOR_QA_ENGINEER_TOPIC_ID, JUNIOR_REACT_NATIVE_DEVELOPER_TOPIC_ID, JUNIOR_DEVOPS_TOPIC_ID, JUNIOR_BUSINESS_ANALYST_TOPIC_ID, JUNIOR_RECRUTER_TOPIC_ID, JUNIOR_PYTHON_DEVELOPER_TOPIC_ID
} from './topic-constants';

// PAGES
export const ADD_NEW_COURSE_PAGE = "/add-course";
export const ADD_NEW_COURSE_SUCCESS_PAGE = "/add-course-success";
export const EDIT_COURSE_PAGE = "/edit-course/:courseId";
export const PROFILE_PAGE = "/profile";
export const LOGIN_PAGE = "/login";
export const REGISTER_PAGE = "/register";
export const REGISTER_ACTIVATE_EMAIL_PAGE = "/after-register";
export const CONFIRM_EMAIL_PAGE = "/confirm-email";

export const SESSION_KEY = "token";
const API_BASE_URL = (process.env.REACT_APP_API || "http://localhost:8080") + "/api/v1";
const API_PUB_BASE = API_BASE_URL + "/pub";

// FILES
export const FILE_IMAGE_PUB_ENDPOINT = API_BASE_URL + "/files/images";

// AUTH
export const LOGIN_ENDPOINT = API_PUB_BASE + "/auth/login";
export const REGISTER_ENDPOINT = API_PUB_BASE + "/auth/register";
export const CONFIRM_EMAIL_ENDPOINT = API_PUB_BASE + "/auth/confirm-email";

export const USER_ENDPOINT = API_BASE_URL + "/users"

// RECORDS
export const RECORDS_ENDPOINT = API_PUB_BASE + "/records";
export const IT_STATUS_ENDPOINT = API_PUB_BASE + "/records/it-status";
export const VALUES_CHANGE_ENDPOINT = API_PUB_BASE + "/records/values-change";
export const TABLE_DATA_ENDPOINT = API_PUB_BASE + "/records/table-data";
export const COMPANIES_EMPLOYEES_TABLE_DATA_ENDPOINT = API_PUB_BASE + "/records/table-data/companies-employees";
export const COMPANIES_EMPLOYEES_TOP_5_TABLE_DATA_ENDPOINT = API_PUB_BASE + "/records/table-data/companies-employees?limit=5";
export const LATEST_RECORD_DATE_ENDPOINT = API_PUB_BASE + "/records/latest-record-date";
export const MOST_DEMANDED_TOPIC_ENDPOINT = API_PUB_BASE + "/records/most-demanded-topic";
export const RECORDS_FOR_TOPICS_ENDPOINT = API_PUB_BASE + "/records/all-for-topics";
export const TOPICS_BY_IDS_ENDPOINT = API_PUB_BASE + "/records/topics/by-ids";
export const TOTAL_NUMBER_OF_EMPLOYEES_IN_TRACKED_COMPANIES_PER_ENDPOINT = API_PUB_BASE + "/records/total-number-of-employees-in-tracked-companies-per-month";

//  COURSES
export const COURSES_PUB_ENDPOINT = API_PUB_BASE + "/courses";
export const COURSES_ENDPOINT = API_BASE_URL + "/courses";
export const COURSE_BY_ID_ENDPOINT = API_BASE_URL + "/courses/{courseId}";
export const COURSES_AUTHOR_ENDPOINT = COURSES_ENDPOINT + "/author";
export const SEND_COURSE_TO_REVIEW_ENDPOINT = COURSES_ENDPOINT + "/{courseId}/toReview";
export const CHANGE_COURSE_VISIBILITY_ENDPOINT = COURSES_ENDPOINT + "/{courseId}/visibility";

// COURSES TAGS
export const COURSE_TAGS_ENDPOINT = API_BASE_URL + "/courses-tags";
export const COUNTRIES_ENDPOINT = API_BASE_URL + "/countries";

// BLOG
export const BLOG_POSTS_ENDPOINT = API_PUB_BASE + "/blog-posts"
export const BLOG_POST_BY_ID_ENDPOINT = API_PUB_BASE + "/blog-posts/{blogPostId}"

// PUBLICATIONS
export const PUBLICATIONS_ENDPOINT = API_PUB_BASE + "/publications"

// ===========================================================================================================================================


export const frontendFrameworksTopicsIds = [REACT_TOPIC_ID, ANGULAR_TOPIC_ID, VUE_TOPIC_ID]

export const juniorJobsTopicsIds = [
  JUNIOR_JAVA_DEVELOPER_TOPIC_ID, JUNIOR_NET_DEVELOPER_TOPIC_ID, JUNIOR_NODE_DEVELOPER_TOPIC_ID, JUNIOR_PHP_DEVELOPER_TOPIC_ID,
  JUNIOR_CPLUSPLUS_DEVELOPER_TOPIC_ID, JUNIOR_GO_DEVELOPER_TOPIC_ID, JUNIOR_ANDROID_DEVELOPER_TOPIC_ID, JUNIOR_RUBY_DEVELOPER_TOPIC_ID,
  JUNIOR_IOS_DEVELOPER_TOPIC_ID, JUNIOR_SCALA_DEVELOPER_TOPIC_ID, JUNIOR_REACT_DEVELOPER_TOPIC_ID, JUNIOR_ANGULAR_DEVELOPER_TOPIC_ID,
  JUNIOR_VUE_DEVELOPER_TOPIC_ID, JUNIOR_UNITY_DEVELOPER_TOPIC_ID, JUNIOR_PROJECT_MANAGER_TOPIC_ID, JUNIOR_SALESFORCE_DEVELOPER_TOPIC_ID,
  JUNIOR_TEST_AUTOMATION_ENGINEER_TOPIC_ID, JUNIOR_QA_ENGINEER_TOPIC_ID, JUNIOR_REACT_NATIVE_DEVELOPER_TOPIC_ID, JUNIOR_DEVOPS_TOPIC_ID,
  JUNIOR_BUSINESS_ANALYST_TOPIC_ID, JUNIOR_RECRUTER_TOPIC_ID, JUNIOR_PYTHON_DEVELOPER_TOPIC_ID
]

// urls for fetching data for tables

export const LANGUAGES_TABLE_FULL_DATA_ENDPOINT = TABLE_DATA_ENDPOINT + "?topics=" + [JAVASCRIPT_TOPIC_ID, JAVA_TOPIC_ID, PYTHON_TOPIC_ID, CSHARP_TOPIC_ID,
  TYPESCRIPT_TOPIC_ID, PHP_TOPIC_ID, CPLUSPLUS_TOPIC_ID, GO_TOPIC_ID, KOTLIN_TOPIC_ID, RUBY_TOPIC_ID, SWIFT_TOPIC_ID,
  SCALA_TOPIC_ID, OBJECTIVEC_TOPIC_ID, DELPHI_TOPIC_ID].join(",");

export const LANGUAGES_TABLE_TOP5_DATA_ENDPOINT = LANGUAGES_TABLE_FULL_DATA_ENDPOINT + "&limit=5";

export const FRONTEND_FRAMEWORKS_TABLE_DATA_ENDPOINT = TABLE_DATA_ENDPOINT + "?topics=" + frontendFrameworksTopicsIds.join(",");

export const DEVELOPERS_TABLE_FULL_DATA_ENDPOINT = TABLE_DATA_ENDPOINT + "?topics=" + [JAVA_DEVELOPER_TOPIC_ID, PHP_DEVELOPER_TOPIC_ID, NET_DEVELOPER_TOPIC_ID,
  REACT_DEVELOPER_TOPIC_ID, PYTHON_DEVELOPER_TOPIC_ID, ANDROID_DEVELOPER_TOPIC_ID, IOS_DEVELOPER_TOPIC_ID,
  UNITY_DEVELOPER_TOPIC_ID, SALESFORCE_DEVELOPER_TOPIC_ID, VUE_DEVELOPER_TOPIC_ID, REACT_NATIVE_DEVELOPER_TOPIC_ID, RUST_DEVELOPER_TOPIC_ID,
  CPLUSPLUS_DEVELOPER_TOPIC_ID, ONEC_DEVELOPER_TOPIC_ID, GO_DEVELOPER_TOPIC_ID, RUBY_DEVELOPER_TOPIC_ID, DELPHI_DEVELOPER_TOPIC_ID,
  FLUTTER_DEVELOPER_TOPIC_ID, WORDPRESS_DEVELOPER_TOPIC_ID, SCALA_DEVELOPER_TOPIC_ID].join(",");

export const DEVELOPERS_TABLE_TOP5_DATA_ENDPOINT = DEVELOPERS_TABLE_FULL_DATA_ENDPOINT + "&limit=5";

