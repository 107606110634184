import { useEffect, useState } from "react";
import { IT_STATUS_ENDPOINT } from "../../common/constants";
import { formatDate } from '../../common/utils';
import HtmlHeader from "../html/html-header";
import './index.scss';

const BelarusItJobsStatus = () => {

  const WEEK = "week";
  const MONTH = "month";

  const [data, setData] = useState(0);
  const [value, setValue] = useState(0);
  const [period, setPeriod] = useState(MONTH);

  useEffect(() => {

    fetch(IT_STATUS_ENDPOINT, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      return response.json()
    })
      .then(data => {
        const { monthChange } = data;
        setValue(monthChange);
        setData(data);
      }).catch(function (reason) {
        console.log(reason);
      });

  }, []);

  const changePeriod = (period) => {
    const { change, monthChange } = data;
    setPeriod(period);
    if (period === WEEK) {
      setValue(change);
    } else {
      setValue(monthChange);
    }
  }

  if (!data) {
    return (<div className="bel-it-jobs-status container">
      Загрузка...
    </div>);
  }

  const { date } = data;
  const dateFormatter = formatDate(date);

  const renderChange = () => {
    const clazzName = value >= 0 ? 'positive' : 'negative';
    const periodName = period === WEEK ? "неделю" : "месяц";
    const averageChangePercents = Math.abs(value / 100.0);
    if (value >= 0) {
      return (<div className={clazzName}>
        Рост на {averageChangePercents} % за {periodName}
      </div>)
    } else {
      return (<div className={clazzName}>
        Падение на {averageChangePercents} % за {periodName}
      </div>)
    }
  }

  return (
    <div className="bel-it-jobs-status container">
      <div className="bel-it-jobs-status__header">
        <HtmlHeader text="Состояние рынка IT вакансий в Беларуси" type="h3" />
      </div>
      <div className="bel-it-jobs-status__content">
        <div className="bel-it-jobs-status-new__left">
          {renderChange()}
        </div>
        <div className="bel-it-jobs-status-new__right">
          <div>
            {value > 0 ? <span>😀</span> : <span>😟</span>}
          </div>
        </div>
      </div>
      <div className="bel-it-jobs-status__controls">
        <div className="bel-it-jobs-status__date"> обновлено {dateFormatter}</div>
        <div className="bel-it-jobs-status__period-container">
          <div
            className={"bel-it-jobs-status__period " + (period === WEEK ? 'devpulse-selected' : 'devpulse-not-selected')}
            onClick={() => changePeriod("week")}>
            неделя
          </div>
          <div
            className={"bel-it-jobs-status__period " + (period === MONTH ? 'devpulse-selected' : 'devpulse-not-selected')}
            onClick={() => changePeriod("month")}>
            месяц
          </div>
        </div>
      </div>
    </div>);
}

export default BelarusItJobsStatus;