import {
  BOOST_TOPIC_ID,
  // CPLUSPLUS_DEVELOPER_TOPIC_ID,
  CPLUSPLUS_TOPIC_ID,
  JUNIOR_CPLUSPLUS_DEVELOPER_TOPIC_ID,
  MIDDLE_CPLUSPLUS_DEVELOPER_TOPIC_ID,
  // NODEJS_TOPIC_ID,
  SENIOR_CPULSPLUS_DEVELOPER_TOPIC_ID,
  STL_TOPIC_ID,
} from "../../../common/topic-constants";
import HtmlHeader from "../../../components/html/html-header";
import TopicChartContainer from "../../../components/charts/topic-chart-container";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { AbstractPage } from '../../abstractPage';

const CplusplusPage = () => {
  return (<AbstractPage>
    <HtmlHeader text="Страница языка C++" type="h1" withMargin={true} />
    <TopicChartContainer topicId={CPLUSPLUS_TOPIC_ID} />
    <Tabs>
      <TabList>
        <Tab>Позиции</Tab>
        <Tab>Библиотеки</Tab>
      </TabList>
      <TabPanel>
        <HtmlHeader text="Позиции для C++ разработчиков:" type="h2" withMargin={true} />
        {/* <TopicChartContainer topicId={CPLUSPLUS_DEVELOPER_TOPIC_ID} /> */}
        <TopicChartContainer topicId={JUNIOR_CPLUSPLUS_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={MIDDLE_CPLUSPLUS_DEVELOPER_TOPIC_ID} />
        <TopicChartContainer topicId={SENIOR_CPULSPLUS_DEVELOPER_TOPIC_ID} />
      </TabPanel>
      <TabPanel>
        <HtmlHeader text="Библиотеки на языке C++:" type="h2" withMargin={true} />
        <TopicChartContainer topicId={STL_TOPIC_ID} />
        <TopicChartContainer topicId={BOOST_TOPIC_ID} />
      </TabPanel>
    </Tabs>
  </AbstractPage>);
}

export default CplusplusPage;