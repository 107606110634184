// import CoursesContainer from '../../../components/courses-container';
import { useParams } from 'react-router-dom';
import { Course } from '../../../components/courses/course';
import RelatedPages from '../../../components/related-pages';
import { AbstractPage } from '../../abstractPage';
import { courseRelatedPagesList } from '../../related-lists';

const CoursePage = () => {

  const { courseId } = useParams();

  return (<AbstractPage>
    <Course courseId={courseId} />
    <RelatedPages list={courseRelatedPagesList} />
  </AbstractPage>);
}

export default CoursePage;