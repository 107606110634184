import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { SimpleTable } from '../simple-table';
import './index.scss';

export const ConfigurableTable = (props) => {

  const {
    title, showFullTable = true, showTitle, apiUrl,
    rowsClickable, anchorId, columns, dataMapper, fullTablePageUrl, removeFirstColumnInMobileVersion
  } = props;

  const [data, setData] = useState(null);
  const history = useHistory();

  useEffect(() => {
    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => response.json())
      .then(data => {
        setData(data);
      });

  }, [apiUrl]);

  if (!data) {
    return (<div className={`configurable-table-container ` + (showFullTable ? 'configurable-table-container__full' : 'configurable-table-container__top5')}>
      Загрузка...
    </div>);
  }

  let place = 0;
  const values = data.map(item => {
    place++;
    return dataMapper({ item, place });
  });

  return (<div
    id={anchorId}
    className={'configurable-table-container ' + (removeFirstColumnInMobileVersion ? "configurable-table-container__remove-first" : "")}
  >
    {showTitle && <h3 className="configurable-table__title">{title}</h3>}
    <SimpleTable settings={{
      columns,
      data: values,
      rowsClickable
    }} history={history} />
    {
      showFullTable ||
      <div className="configurable-table__link">
        <Link to={fullTablePageUrl}>Смотреть всю таблицу</Link>
      </div>
    }

  </div>);
}