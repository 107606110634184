// import CoursesContainer from '../../../components/courses-container';
import { useParams } from 'react-router-dom';
import { EditCourse } from '../../../components/courses/edit-course';
import { AbstractPage } from '../../abstractPage';

const EditCoursePage = () => {

  const { courseId } = useParams();

  return (<AbstractPage>
    <EditCourse courseId={courseId} />
  </AbstractPage>);
}

export default EditCoursePage;