import { useEffect } from 'react';
import {
  COMPANIES_EMPLOYEES_TABLE_DATA_ENDPOINT,
} from '../../common/constants';
import HtmlHeader from "../../components/html/html-header";
import { AbstractPage } from '../abstractPage';
import { TopItCompaniesByEmployeesTable } from '../../components/tables/top-it-companies-by-employees';
import PageUpdated from '../../components/page-updated';
import RelatedPages from '../../components/related-pages/index.jsx';
import { topCompaniesRelatedList } from '../related-lists';
import ItCompaniesEmployeesPerDateLineChart from '../../components/charts/it-companies-empoyees-per-date-line-chart';
import Keywords from '../../components/html/keywords';

export const TopItCompaniesByEmployeesTablePage = () => {

  useEffect(() => {
    document.title = "IT компании Беларуси. Количество сотрудников | Devpulse.by — аналитика рынка IT вакансий в Беларуси";
  }, []);

  return (<AbstractPage>
    <PageUpdated withMargin={true} />
    <HtmlHeader text="IT компании Беларуси. Количество сотрудников" type="h1" withMargin={true} />
    <div className="header-margin">Данные взяты из профилей компаний в LinkedIn</div>
    <ItCompaniesEmployeesPerDateLineChart title="Суммарное количество сотрудников в мире и в РБ" anchorId="" />
    <TopItCompaniesByEmployeesTable
      showTitle={false}
      showFullTable={true}
      apiUrl={COMPANIES_EMPLOYEES_TABLE_DATA_ENDPOINT}
      rowsClickable={false}

    // rowLinkBase="/languages/"
    // rowLinkParamName="topicId"
    // showPlace={true}
    // showWeekChange={false}
    // showMonthChange={true}

    />
    <RelatedPages list={topCompaniesRelatedList} />
    <Keywords values={["it компании беларуси", "топ it-компаний беларуси 2022", "самые крупные it компании беларуси", "it компании беларуси 2023",
      "топ it компаний беларуси", "топ айти компаний беларуси", "крупнейшие it компании беларуси", "it компании", "it компании минск",
    ]} />
  </AbstractPage>);
}